// REGEXES
export const textOnlyRegex = new RegExp(/^[a-zA-Z ]*$/);
export const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
export const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?\-\_\+\=\(\)\[\]\{\}\;\:\'\"\<\,\>\.\/\\\|\~\`])(?=.{10,})/
);
export const sixDigitRegex = new RegExp(/^\d{6}$/);
export const numbersOnlyRegex = new RegExp(/^[0-9]+$/);
export const phoneNumberRegex = new RegExp(/^[0-9_/#+-\\(\\)]*$/);
export const zipCodeRegex = /^[a-zA-Z0-9\s-]*$/;
export const decimalRegex = new RegExp(/^(?![.])\d*\.?\d{0,3}$/);
export const numbersRangeOnlyRegex = new RegExp(/^[0-9]+(-[0-9]+)*$|^[-]+$/);

// LINKS
export const meraxisEmail = 'mailto:customer.portal@meraxis-group.com';
export const meraxisGroupLink = 'www.meraxis-group.com';
export const meraxisGroupImpressum = 'https://www.meraxis-group.com/impressum';

// LANGUAGES
export enum LANGUAGES {
  ENGLISH = 'EN',
  GERMAN = 'DE',
  FRENCH = 'FR',
}
export const LANGUAGES_TYPES = ['en', 'de', 'it', 'fr'];

// LOCAL STORAGE ITEMS
export enum LOCAL_STORAGE_ITEMS {
  TOKEN = 'userToken',
  ACCOUNT = 'user',
  REFRESH_TOKEN = 'userRefreshToken',
  SIDEBAR = 'sidebar',
  LANGUAGE = 'language',
}

// REDIRECTS
export enum APP_REDIRECTS {
  DASHBOARD = '/dashboard',
  LOGIN = '/login',
  PRODUCTS = '/dashboard/products',
  NOT_FOUND = '/not-found',
}

// ROLES
export enum ROLES {
  ADMIN = 'ROLE_ADMIN',
  SUPPLIER = 'ROLE_SUPPLIER',
  SUPERADMIN = 'ROLE_SUPERADMIN',
}

// SORTER
export enum ALIGN {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum STATUS_PRODUCT {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  DRAFT = 'DRAFT',
  SAMPLE_REQUESTED = 'SAMPLE_REQUESTED',
  SHIPMENT_CONFIRMED = 'SHIPMENT_CONFIRMED',
  DELETED = 'DELETED',
  NEW = 'NEW',
  INACTIVE = 'INACTIVE',
}

// SUPPLIERS

export enum SUPPLIER_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NEW = 'NEW',
}

export enum PRODUCT_CERTIFICATES {
  MSDS = 'MSDS',
  REACH_SVHC = 'REACH/SVHC',
  ROHS = 'ROHS',
  EU_CERT = 'EUCert',
  RECY_CLASS = 'RecyClass',
  FDA = 'FDA',
  EFSA = 'EFSA',
  PFAS = 'PFAS',
  HEAVY_METALS = 'Heavy metals',
  CO2 = 'CO2',
  ISO9001 = 'ISO 9001',
  IATF_VDA = 'IATF/VDA',
  OTHER = 'OTHER',
}

export const certificatesValues = [
  { label: PRODUCT_CERTIFICATES.CO2, value: 'CO2' },
  { label: PRODUCT_CERTIFICATES.EFSA, value: 'EFSA' },
  { label: PRODUCT_CERTIFICATES.EU_CERT, value: 'EU_CERT' },
  { label: PRODUCT_CERTIFICATES.FDA, value: 'FDA' },
  { label: PRODUCT_CERTIFICATES.HEAVY_METALS, value: 'HEAVY_METALS' },
  { label: PRODUCT_CERTIFICATES.IATF_VDA, value: 'IATF_VDA' },
  { label: PRODUCT_CERTIFICATES.ISO9001, value: 'ISO9001' },
  { label: PRODUCT_CERTIFICATES.MSDS, value: 'MSDS' },
  { label: PRODUCT_CERTIFICATES.PFAS, value: 'PFAS' },
  { label: PRODUCT_CERTIFICATES.REACH_SVHC, value: 'REACH_SVHC' },
  { label: PRODUCT_CERTIFICATES.RECY_CLASS, value: 'RECY_CLASS' },
  { label: PRODUCT_CERTIFICATES.ROHS, value: 'ROHS' },
  { label: PRODUCT_CERTIFICATES.OTHER, value: 'OTHER' },
];

export enum COMPANY_CERTIFICATES {
  REACH = 'REACH',
  EU_CERT = 'EUCert',
  RECY_CLASS = 'RecyClass',
  ISO9001 = 'ISO 9001',
  ISO14001 = 'ISO 14001',
  IATF = 'IATF',
  VDA = 'VDA',
  NONE = 'none',
  OTHER = 'other',
}

export const comapnyCertificatesValues = [
  { label: COMPANY_CERTIFICATES.EU_CERT, value: 'EUCert' },
  { label: COMPANY_CERTIFICATES.IATF, value: COMPANY_CERTIFICATES.IATF },
  { label: COMPANY_CERTIFICATES.ISO9001, value: 'ISO 9001' },
  { label: COMPANY_CERTIFICATES.ISO14001, value: 'ISO 14001' },
  { label: COMPANY_CERTIFICATES.REACH, value: COMPANY_CERTIFICATES.REACH },
  { label: COMPANY_CERTIFICATES.RECY_CLASS, value: 'RecyClass' },
  { label: COMPANY_CERTIFICATES.VDA, value: COMPANY_CERTIFICATES.VDA },
  { label: COMPANY_CERTIFICATES.NONE, value: 'None' },
  { label: COMPANY_CERTIFICATES.OTHER, value: 'OTHER' },
];

export const acceptFileUploaded = '.png, .jpg, .jpeg, .doc, .xls, .pdf';

export enum DOCUMENT_TYPES {
  TDS = 'TDS',
  TDS_ANONYMOUS = 'TDS_ANONYMOUS',
  MSDS = 'MSDS',
  REACH = 'REACH',
  FOOD_CONTACT_EFSA = 'FOOD_CONTACT_EFSA',
  FOOD_CONTACT_FDA = 'FOOD_CONTACT_FDA',
  SVHC = 'SVHC',
  SML = 'SML',
  ROHS = 'ROHS',
  SUPPLIER_CO2 = 'SUPPLIER_CO2',
  OTHER = 'OTHER',
}

export const productDocumentTypes = [
  { key: 'tds', value: 'TDS', show: true, update: true },
  { key: 'msds', value: 'MSDS', show: true, update: true },
  { key: 'reach', value: 'REACH', show: true, update: true },
  { key: 'foodefsa', value: 'FOOD_CONTACT_EFSA', show: true, update: true },
  { key: 'foodfda', value: 'FOOD_CONTACT_FDA', show: true, update: true },
  { key: 'svhc', value: 'SVHC', show: true, update: false },
  { key: 'sml', value: 'SML', show: true, update: true },
  { key: 'rohs', value: 'ROHS', show: true, update: true },
  { key: 'supplierCo2', value: 'SUPPLIER_CO2', show: true, update: true },
  { key: 'other', value: 'OTHER', show: false, update: false },
];

export const titlesPerson = [
  { name: 'NONE', value: 1 },
  { name: 'DR', value: 4 },
  { name: 'MR', value: 2 },
  { name: 'MRS', value: 3 },
];

export const washingList = [
  { label: 'Cold', value: 'COLD' },
  { label: 'Hot', value: 'HOT' },
  { label: 'No', value: 'NO' },
];

import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';

import * as RoleManager from 'services/role';
import { Arrow } from 'components';
import { logoutUser } from 'services/auth';
import { getLocalAccount } from 'services/account';

import './UserProfile.scss';

export const UserProfile = () => {
  const { t } = useTranslation('profile');
  const account = getLocalAccount();
  const anchorRef: any = useRef();

  const [open, setOpen] = useState(false);

  const hidden = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current !== undefined &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button className="btn-profile" ref={anchorRef} onClick={handleToggle}>
        <Box textAlign="right">
          <Typography variant="h6" color="secondary">
            {account.fullName}
          </Typography>
          {!hidden && (
            <Typography
              variant="subtitle2"
              color="secondary"
              textTransform="capitalize"
            >
              {t(account?.authorities.roles[0].name)}
            </Typography>
          )}
        </Box>
        <IconButton>
          <Arrow />
        </IconButton>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className="user-dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom-end',
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem>
                    <Link to="/dashboard/profile" onClick={handleClose}>
                      {t('titleHeader')}
                    </Link>
                  </MenuItem>
                  <Divider />

                  {RoleManager.isAbleTo('roles', 'profileSettings', 'view') && (
                    <>
                      <MenuItem>
                        <Link to="/dashboard/settings" onClick={handleClose}>
                          {t('profileSettings')}
                        </Link>
                      </MenuItem>
                      <Divider />
                    </>
                  )}

                  <MenuItem>
                    <Link to="/" onClick={logoutUser}>
                      {t('logout')}
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const REGISTER_START = 'register/register-start';
export const REGISTER_SUCCESS = 'register/register-success';
export const REGISTER_ERROR = 'register/register-error';
export const GET_ACTIVATION_CODE_SUCCESS =
  'register/register-get-activation-code-success';
export const SEND_ACTIVATION_CODE_SUCCESS =
  'register/register-send-activation-code-success';
export const GET_COMPANY_INFORMATION_SUCCESS =
  'register/register-get-company-information-success';
export const UPDATE_TIMER = 'register/update-timer';
export const ACTIVATION_CODE_ERROR = 'register/activation-code-error';

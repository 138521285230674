import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_ERROR,
  PASSWORD_CHANGE_SUCCESS,
  UPDATE_MOBILE_NUMBER,
  VERIFY_MOBILE_NUMBER,
  MFA_SWITCH_SUCCESS,
} from './ProfileTypes';

type ProfileDataType = {
  data: {
    firstName?: string;
    lastName?: string;
    gender?: string;
    position?: string;
    username?: string;
    companyName?: string;
    mobileNumber?: string;
  };
  success: boolean;
  successMessage?: null | string;
  error: null | string;
  loading: boolean;
};

export const INITIAL_STATE: ProfileDataType = {
  loading: false,
  error: null,
  success: false,
  successMessage: null,
  data: {},
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PROFILE:
      return { ...state, loading: true };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
        },
      };

    case PASSWORD_CHANGE_SUCCESS:
      return { ...state, success: true, loading: false };

    case GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case UPDATE_MOBILE_NUMBER:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          mobileNumber: action.payload,
        },
      };

    case VERIFY_MOBILE_NUMBER:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
        },
      };

    case MFA_SWITCH_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload.successMessage,
        data: {
          ...state.data,
          isMfaEnabled: action.payload.switch,
        },
      };

    case GET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        successMessage: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

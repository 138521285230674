import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Button,
  Grid,
  Alert,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { emailRegex, LANGUAGES, LOCAL_STORAGE_ITEMS } from 'services/config';
import { InputError, LanguageSwitcher, Loading } from 'components';

import './LoginForm.scss';

type LoginFormProps = {
  login: any;
  loginUser: ({}) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({ login, loginUser }) => {
  const language =
    localStorage.getItem(LOCAL_STORAGE_ITEMS.LANGUAGE) ||
    LANGUAGES.ENGLISH.toLowerCase();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation('login');

  const logoImage = process.env.REACT_APP_API_URL_IMAGES + '/login-logo.svg';

  const submitForm: any = ({ username, password }) => {
    loginUser({ username, password });
  };
  return (
    <Box className="login-form-wrap">
      {login && login.loading ? <Loading /> : null}
      <LanguageSwitcher />
      <div
        className="login-logo"
        style={{ backgroundImage: `url(${logoImage})` }}
      />
      <form onSubmit={handleSubmit(submitForm)}>
        <TextField
          {...register('username', { required: true, pattern: emailRegex })}
          margin="normal"
          id="username"
          variant="outlined"
          label={t('labelEmail')}
          data-qa="login-email"
          placeholder={t('labelEmail')}
          inputProps={{
            name: 'username',
          }}
          onBlur={(e: any) => (e.target.value = e.target.value.trim())}
          helperText={
            errors.username && (
              <InputError message={t('validEmailIsRequired')} />
            )
          }
        />
        <TextField
          {...register('password', { required: true })}
          margin="normal"
          type="password"
          id="password"
          variant="outlined"
          label={t('labelPassword')}
          data-qa="login-password"
          placeholder={t('labelPassword')}
          inputProps={{
            name: 'password',
          }}
          helperText={
            errors.password && <InputError message={t('passwordIsRequired')} />
          }
        />
        <br />
        {!!login?.error && (
          <Alert severity="error" className="alert-message">
            <span id="message-id">{t(login.error)}</span>{' '}
          </Alert>
        )}
        <Button
          type="submit"
          className="login-button"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          data-qa="login-button"
        >
          {t('buttonLogin')}
        </Button>
        <Grid container>
          <Grid item sm={6}>
            <Link
              className="forgot-link"
              href="/forgot-password"
              underline="none"
            >
              <Typography variant="subtitle1" data-qa="forgot-password">
                {t('forgotPassword')}
              </Typography>
            </Link>
          </Grid>
          <Grid item sm={6}>
            <Link className="register-link" href="/register" underline="none">
              <Typography variant="subtitle1" align="right" data-qa="register">
                {t('register')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Notifications } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { calculateTimeAgo } from 'services/calculateDate';

import './Notifications.scss';
type NotificationsViewProps = {
  markNotificationAsRead: (
    notificationId: number,
    redirectPath: string
  ) => void;
  deleteNotificationUser: (notificationId: number, page: number) => void;
  getNotifications: (page: number) => void;
  page: any;
  clearAllNotifications: () => void;
};

const NotificationsView = (props: NotificationsViewProps) => {
  const {
    markNotificationAsRead,
    deleteNotificationUser,
    page,
    clearAllNotifications,
    getNotifications,
  } = props;

  const { t } = useTranslation('notifications');

  const notifications = useSelector((state: any) => state.notifications);
  const notificationsData = notifications.notifications;

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleClose() {
    setAnchorEl(null);
    setIsOpen(false);
  }

  const goToNotification = (notification) => {
    markNotificationAsRead(notification.id, notification.path);
    getNotifications(page);
  };

  return (
    <div>
      <IconButton
        size="large"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsOpen(true);
        }}
        className="notifications-button"
        color="primary"
      >
        <Badge
          badgeContent={
            notificationsData?.unreadNotif != 0
              ? notificationsData?.unreadNotif
              : null
          }
          color="info"
        >
          <Notifications className="notifications-component" />
        </Badge>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        className="notifications-popper"
        onClose={handleClose}
        style={{
          top: '30px',
        }}
      >
        <Box>
          <InfiniteScroll
            dataLength={notificationsData?.content?.length}
            next={() =>
              !notificationsData.loadingButton && getNotifications(page + 1)
            }
            hasMore={!notificationsData.last}
            loader={<CircularProgress />}
            height={
              notificationsData?.content?.length > 2
                ? (window.innerHeight * 60) / 100
                : 300
            }
          >
            <Stack
              sx={{
                width: '380px',
                padding: '12px',
              }}
              className="notifications-stack"
              spacing={2}
            >
              <Typography
                variant="h4"
                fontFamily="Gotham"
                fontWeight="600"
                gutterBottom
                className="notifications-title"
              >
                {t('notificationsTitle')}
              </Typography>

              {!!notificationsData.content?.length ? (
                notificationsData.content.map((notification, key) => {
                  const fullDescription = t(notification?.description, {
                    supplierName:
                      notification.descriptionVariables?.supplierName || '',
                    materialName:
                      notification.descriptionVariables?.materialName || '',
                  });

                  const needsShortening = fullDescription.length > 95;
                  const shortDescription = `${fullDescription.slice(0, 94)}...`;
                  return (
                    <Box
                      key={key}
                      className="notification-div"
                      style={
                        !notification.isRead
                          ? { backgroundColor: 'rgb(222, 222, 222)' }
                          : {}
                      }
                    >
                      <Typography
                        variant="body1"
                        onClick={() => goToNotification(notification)}
                      >
                        {t(notification.title)}
                        <Typography variant="subtitle2">
                          {needsShortening ? (
                            <Tooltip title={fullDescription}>
                              <span>{shortDescription}</span>
                            </Tooltip>
                          ) : (
                            fullDescription
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {notification.createdDate
                            ? calculateTimeAgo(notification?.createdDate)
                            : ''}
                        </Typography>
                      </Typography>

                      <IconButton
                        color="secondary"
                        className="icon-action"
                        onClick={() => {
                          deleteNotificationUser(notification.id, page);
                        }}
                      >
                        <ClearIcon color="primary" />
                      </IconButton>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  variant="body1"
                  fontWeight="500"
                  className="empty-notifications"
                >
                  {t('noNotifications')}
                </Typography>
              )}
            </Stack>
          </InfiniteScroll>
          {!!notificationsData?.content?.length && (
            <div className="clear-all-container">
              <Button
                style={{ background: 'transparent', color: '#E4002B' }}
                onClick={clearAllNotifications}
                disabled={notificationsData.loadingButton}
              >
                {t('clearAll')}
              </Button>
            </div>
          )}
        </Box>
      </Popover>
    </div>
  );
};
export default NotificationsView;

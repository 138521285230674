import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_START,
  CODE_SUCCESS,
  CLEAR_ERRORS,
} from './LoginTypes';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {},
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, loading: true, error: null };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
        },
      };

    case CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case LOGIN_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

import API from 'services/api';

export const getNotifications = async (userId: number, page: number) => {
  return await API.get(`/auth/notifications/users/${userId}`, {
    params: { page, size: 30 },
  });
};

export const markNotificationAsRead = async (notificationId: number) => {
  return await API.patch(`/auth/notifications/${notificationId}/read`);
};

export const clearNotifications = async (userId: number) => {
  return await API.get(`/auth/notifications/user/${userId}/clear-all`);
};

export const deleteNotification = async (notificationId: number) => {
  return await API.delete(`/auth/notifications/${notificationId}`);
};

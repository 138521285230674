export const PRODUCTS_START = 'products/products-start';
export const PRODUCTS_SUCCESS = 'products/products-success';
export const PRODUCT_DELETE_SUCCESS = 'products/product-delete-success';
export const PRODUCTS_ERROR = 'products/products-error';
export const SEND_PRODUCT_SUCCESS = 'products/send-product-success';
export const PRODUCTS_PAGINATION = 'products/products-pagination-success';
export const RESET_PRODUCTS = 'products/products-reset';
export const CLEAR_ERRORS = 'products/products-error';
export const ACTION_START = 'products/action-start';
export const ACTION_SUCCESS = 'products/action-success';

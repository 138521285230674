import React, { Suspense } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { LanguageSwitcher, Loading, UserProfile } from 'components';
import Notifications from 'components/Notifications/Notifications';

import './Header.scss';

type HeaderProps = {
  isOpen?: boolean;
  toggleSidebar: Function;
};

export const Header: React.FC<HeaderProps> = ({
  isOpen = true,
  toggleSidebar,
}) => {
  const isLargeScreen = window.outerWidth > 1000;
  const logoHeader = process.env.REACT_APP_API_URL_IMAGES + '/logo.svg';
  const logoHeaderSmall =
    process.env.REACT_APP_API_URL_IMAGES + '/logo-small.svg';

  return (
    <AppBar
      className={`recycling-header ${
        isOpen && isLargeScreen
          ? 'recycling-header--opened'
          : 'recycling-header--closed'
      }`}
      color="default"
      position="fixed"
    >
      <Toolbar>
        <div className="brand">
          <img
            src={
              isOpen && isLargeScreen ? `${logoHeader}` : `${logoHeaderSmall}`
            }
            alt="Recycling Logo"
            className="brand-logo"
          />
        </div>
        <div className="menu-toggle">
          <MenuIcon onClick={() => toggleSidebar()} className="btn-menu" />
        </div>
        <div className="grow"></div>
        <Notifications key={Date.now()} />
        <LanguageSwitcher color="secondary" />
        <Suspense fallback={<Loading />}>
          <UserProfile />
        </Suspense>
      </Toolbar>
    </AppBar>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import {
  clearNotifications,
  deleteNotification,
  getNotifications,
  markNotificationAsRead,
} from './NotificationsActions';
import { LOCAL_STORAGE_ITEMS, ROLES } from 'services/config';
import NotificationsView from './NotificationsView';

const Notifications = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const account = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCOUNT) || '{}'
  );

  const markAsRead = (notificationId: number, redirectPath: string) => {
    dispatch(markNotificationAsRead(notificationId, redirectPath));
  };

  const deleteNotificationUser = (notificationId: number, page: number) => {
    setPage(0);
    dispatch(deleteNotification(notificationId, account.id, page));
  };
  const updateNotifications = async (nextPage: number) => {
    setPage(nextPage);
    dispatch(getNotifications(account.id, nextPage));
  };

  const clearAllNotifications = () => {
    dispatch(clearNotifications(account.id));
  };

  useEffect(() => {
    dispatch(getNotifications(account.id, page));
  }, []);

  return (
    <>
      {![ROLES.SUPPLIER, ROLES.ADMIN].includes(
        account.authorities.roles[0].name
      ) ? (
        <Box></Box>
      ) : (
        <NotificationsView
          markNotificationAsRead={markAsRead}
          deleteNotificationUser={deleteNotificationUser}
          getNotifications={updateNotifications}
          page={page}
          clearAllNotifications={clearAllNotifications}
        />
      )}
    </>
  );
};

export default Notifications;

import { getLocalAccount } from './account';

export const isAbleTo = (
  permission: string,
  namespace: string,
  action: string
) => {
  const localUser = getLocalAccount();
  let ableArr: any = [];

  if (
    !localUser ||
    !localUser.authorities ||
    !localUser.authorities.roles ||
    !localUser.authorities.tenant
  ) {
    return false;
  }

  const PERMISSION_NAME = localUser.authorities[permission][0].name;
  const PERMISSIONS = JSON.parse(
    localUser.authorities[permission][0].permissions || '{}'
  );

  const ROLE_MAP = {
    [PERMISSION_NAME]: PERMISSIONS,
  };

  (localUser.authorities[permission] || []).forEach((authority) => {
    if (
      ROLE_MAP[authority.name] &&
      ROLE_MAP[authority.name][namespace] &&
      ROLE_MAP[authority.name][namespace][action]
    ) {
      ableArr.push(ROLE_MAP[authority.name][namespace][action]);
    }
  });

  let isAble = false;
  ableArr.forEach((able) => {
    if (able) isAble = true;
  });

  return isAble;
};

import { APP_REDIRECTS } from 'services/config';
import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  TOKEN_VALID,
  TOKEN_ERROR,
  RESEND_ACTIVATION_LINK_SUCCESS,
} from './ResetPasswordTypes';
import * as AccountRepository from 'repository/account';

export const resetPassword = (data: { key: string; newPassword: string }) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_START,
    });

    try {
      await AccountRepository.resetPasswordFinish(data);

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });

      window.location.href = APP_REDIRECTS.LOGIN;
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const checkKey = (key: { key: string }) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_START,
    });

    try {
      const response = await AccountRepository.resetKeyValidity(key);

      if (response && response.data.status === 'VALID') {
        dispatch({
          type: TOKEN_VALID,
        });
      } else {
        dispatch({
          type: TOKEN_ERROR,
          payload: response.data.status,
        });
      }
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};

      dispatch({
        type: TOKEN_ERROR,
        payload: errorData.title || errorData.detail || errorData.exception,
      });
    }
  };
};

export const resendActivationLink = (data) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_START,
    });

    try {
      await AccountRepository.resendActivationLink(data);

      dispatch({
        type: RESEND_ACTIVATION_LINK_SUCCESS,
      });
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const clearErrors = () => ({
  type: RESET_PASSWORD_ERROR,
  payload: null,
});

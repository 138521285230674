import {
  PROFILE_SETTINS_START,
  PROFILE_SETTINS_SUCCESS,
  PROFILE_SETTINS_ERROR,
  RESET_ERROR_SUCCESS,
} from './ProfileSettingsType';

export const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROFILE_SETTINS_START:
      return { ...state, loading: true };

    case PROFILE_SETTINS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE_SETTINS_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration: {
          language: action.payload?.language?.toUpperCase() || 'EN',
        },
      };

    case RESET_ERROR_SUCCESS:
      return { ...state, loading: false, error: null, success: false };

    default:
      return state;
  }
};

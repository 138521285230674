import React from 'react';
export const Profile = React.lazy(() => import('./admin/Profile/Profile'));
export const ForgotPassword = React.lazy(
  () => import('./public/ForgotPassword/ForgotPassword')
);
export const ResetPassword = React.lazy(
  () => import('./public/ResetPassword/ResetPassword')
);
export const Register = React.lazy(() => import('./public/Register/Register'));
export const NotFound = React.lazy(() => import('./public/NotFound/NotFound'));
export const Login = React.lazy(() => import('./public/Login/Login'));
export const ProfileSettings = React.lazy(
  () => import('./admin/ProfileSettings/ProfileSettings')
);
export const ProductDetails = React.lazy(
  () => import('./admin/ProductForm/ProductForm')
);
export const Products = React.lazy(() => import('./admin/Products/Products'));
export const SendProductsDetails = React.lazy(
  () => import('./admin/SendProductsDetails/SendProductsDetails')
);
export const Suppliers = React.lazy(
  () => import('./admin/Suppliers/Suppliers')
);
export const SupplierConfirmation = React.lazy(
  () => import('./admin/SupplierConfirmation/SupplierConfirmation')
);
export const SupplierProducts = React.lazy(
  () => import('./admin/SupplierProducts/SupplierProducts')
);
export const Imprint = React.lazy(() => import('./admin/Imprint/Imprint'));
export const TermsAndConditions = React.lazy(
  () => import('./admin/TermsContainer/TermsContainer')
);
export const TermsAndConditionsPublic = React.lazy(
  () => import('./public/TermsAndConditions/TermsAndConditions')
);
export const PrivacyPolicy = React.lazy(
  () => import('./admin/PrivacyPolicy/PrivacyPolicy')
);

import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ConfirmationDialog = {
  dialogOpen: boolean;
  handleDialogClose: Function;
  translation: string;
  actionProduct: Function;
  item?: any;
  itemId?: number;
};

export const ConfirmationDialog: React.FC<ConfirmationDialog> = ({
  dialogOpen,
  handleDialogClose,
  actionProduct,
  translation,
  item,
  itemId,
}) => {
  const { t } = useTranslation(`${translation}`);

  return (
    <Dialog
      className="dialog-confirmation"
      open={dialogOpen}
      maxWidth={'xs'}
      onClose={() => handleDialogClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography variant="h4">{itemId ? t('titleHeaderDelete') : t('titleBackFromMaterial')}</Typography>
      </DialogTitle>
      <DialogContent style={{ padding: '20px 24px' }}>
        <DialogContentText id="alert-dialog-description">
          {itemId ? (item.id === itemId
            ? !item.batches
              ? t('descriptionDeleteMaterial')
              : t('descriptionDeleteAllBatches')
            : t('descriptionDeleteBatch')) : t('descriptionBackFromMaterial')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          color="success"
          onClick={() => handleDialogClose(false)}
        >
          <Typography variant="button">{t('no')}</Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            itemId ? actionProduct('DELETE', itemId, !!item.parentId): actionProduct();
            handleDialogClose(false);
          }}
        >
          <Typography variant="button">{t('yes')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

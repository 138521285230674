import {
  GET_TERMS_START,
  GET_TERMS_SUCCESS,
  GET_TERMS_ERROR,
} from './TermsAndConditionsTypes';
import * as TermsAndConditionsRepository from '../../repository/privacyAndTerms';

export const getTerms = (localLang) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TERMS_START,
    });
    try {
      const response =
        await TermsAndConditionsRepository.getPrivacyPolicyTermsAndConditions(localLang);
      dispatch({
        type: GET_TERMS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_TERMS_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const updateTerms = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_TERMS_START,
    });
    try {
      await TermsAndConditionsRepository.updateTermsAndConditions();
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_TERMS_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

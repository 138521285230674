import { FC } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AdminLayout, PublicLayout } from 'layouts';
import { ADMIN_PATH, PUBLIC_PATH, ROUTES } from 'routes/const';
import {
  NotFound,
  Profile,
  ProfileSettings,
  ProductDetails,
  Products,
  SendProductsDetails,
  Suppliers,
  SupplierConfirmation,
  SupplierProducts,
  Register,
  ResetPassword,
  ForgotPassword,
  Login,
  Imprint,
  TermsAndConditions,
  TermsAndConditionsPublic,
  PrivacyPolicy,
} from 'containers';
import * as RoleManager from 'services/role';

const routeObjects: any = [
  {
    path: ADMIN_PATH,
    Component: AdminLayout,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.ADMIN.DASHBOARD} />,
        allowed: true,
      },
      {
        path: ROUTES.ADMIN.PRODUCTS,
        Component: Products,
        allowed: RoleManager.isAbleTo('roles', 'productsOverview', 'view'),
      },
      {
        path: ROUTES.ADMIN.PRODUCT_VIEW_MATERIAL,
        Component: ProductDetails,
        allowed: RoleManager.isAbleTo('roles', 'productDetails', 'view'),
      },
      {
        path: ROUTES.ADMIN.PRODUCT_VIEW_BATCH,
        Component: ProductDetails,
        allowed: RoleManager.isAbleTo('roles', 'productDetails', 'view'),
      },
      {
        path: ROUTES.ADMIN.PRODUCT_CREATE_MATERIAL,
        Component: ProductDetails,
        allowed: RoleManager.isAbleTo('roles', 'productDetails', 'create'),
      },
      {
        path: ROUTES.ADMIN.PRODUCT_CREATE_BATCH,
        Component: ProductDetails,
        allowed: RoleManager.isAbleTo('roles', 'productDetails', 'create'),
      },
      {
        path: ROUTES.ADMIN.PRODUCT_UPDATE_MATERIAL,
        Component: ProductDetails,
        allowed: RoleManager.isAbleTo('roles', 'productDetails', 'update'),
      },
      {
        path: ROUTES.ADMIN.PRODUCT_UPDATE_BATCH,
        Component: ProductDetails,
        allowed: RoleManager.isAbleTo('roles', 'productDetails', 'update'),
      },
      {
        path: ROUTES.ADMIN.SEND_PRODUCT_DETAILS,
        Component: SendProductsDetails,
        allowed: RoleManager.isAbleTo('roles', 'sendProductDetails', 'view'),
      },
      {
        path: ROUTES.ADMIN.SUPPLIERS,
        Component: Suppliers,
        allowed: RoleManager.isAbleTo('roles', 'suppliersOverview', 'view'),
      },
      {
        path: ROUTES.ADMIN.SUPPLIER_PRODUCTS,
        Component: SupplierProducts,
        allowed: RoleManager.isAbleTo('roles', 'supplierDetails', 'view'),
      },
      {
        path: ROUTES.ADMIN.SUPPLIER_CONFIRMATION,
        Component: SupplierConfirmation,
        allowed: RoleManager.isAbleTo('roles', 'supplierConfirmation', 'view'),
      },
      {
        path: ROUTES.ADMIN.SUPPLIER_VIEW,
        Component: SupplierConfirmation,
        allowed: RoleManager.isAbleTo('roles', 'supplierConfirmation', 'view'),
      },
      {
        path: ROUTES.ADMIN.PROFILE,
        Component: Profile,
        allowed: RoleManager.isAbleTo('roles', 'profile', 'view'),
      },
      {
        path: ROUTES.ADMIN.PROFILE_SETTINGS,
        Component: ProfileSettings,
        allowed: RoleManager.isAbleTo('roles', 'profileSettings', 'view'),
      },
      {
        path: ROUTES.ADMIN.IMPRINT,
        Component: Imprint,
        allowed: true,
      },
      {
        path: ROUTES.ADMIN.TERMS,
        Component: TermsAndConditions,
        allowed: true,
      },
      {
        path: ROUTES.ADMIN.PRIVACY_POLICY,
        Component: PrivacyPolicy,
        allowed: true,
      },
      {
        path: '*',
        element: <Navigate to={'/' + ROUTES.PUBLIC.NOT_FOUND} />,
        allowed: true,
      },
    ],
  },
  {
    path: PUBLIC_PATH,
    Component: PublicLayout,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.PUBLIC.LOGIN} />,
        allowed: true,
      },
      {
        path: ROUTES.PUBLIC.LOGIN,
        Component: Login,
        allowed: true,
      },
      {
        path: ROUTES.PUBLIC.REGISTER,
        Component: Register,
        allowed: true,
      },
      {
        path: ROUTES.PUBLIC.RESET_PASSWORD,
        Component: ResetPassword,
        allowed: true,
      },
      {
        path: ROUTES.PUBLIC.FORGOT_PASSWORD,
        Component: ForgotPassword,
        allowed: true,
      },
      {
        path: ROUTES.PUBLIC.TERMS,
        Component: TermsAndConditionsPublic,
        allowed: true,
      },
      {
        path: ROUTES.PUBLIC.NOT_FOUND,
        Component: NotFound,
        allowed: true,
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.PUBLIC.NOT_FOUND} replace />,
        allowed: false,
      },
    ],
  },
];

export const Routes: FC = () => {
  const modifiedRouteObjects = routeObjects.map((routeObject) => {
    const children = routeObject.children.filter((child) => child.allowed);

    return {
      ...routeObject,
      children,
    };
  });

  const routes: any = useRoutes(modifiedRouteObjects);
  return routes;
};

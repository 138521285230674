import React from 'react';
import { Typography, Grid, Box, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './PageTitle.scss';

type PageTitleProps = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  showBack?: boolean;
  backLink?: string;
  getLink?: any;
  isView?: boolean;
  onBack?: () => void;
};

export const PageTitle: React.FC<PageTitleProps> = ({
  title = '',
  children,
  showBack,
  backLink,
  getLink,
  isView,
  onBack
}) => {
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      !isView ? getLink(backLink) : backLink?.length;
    }
  };

  return (
    <Box mb={2} className="page-title">
      <Grid container className="page-title-container">
        <Grid item className="page-title-item">
          {showBack && (
            <Fab
              onClick={handleBack}
              size="small"
              className="back-button"
              aria-label="edit"
            >
              <ArrowBackIcon />
            </Fab>
          )}
          <Typography variant="caption" className="page-title-typography">
            {title}
          </Typography>
        </Grid>
        <Grid item className="title-cell" xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

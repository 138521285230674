import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import './Loading.scss';

type LoadingProps = {
  fullWidth?: string;
};

export const Loading: React.FC<LoadingProps> = ({ fullWidth }) => (
  <Box className={`loading-wrapper ${fullWidth && 'loading-wrapper-full'}`}>
    <Box className="loading-bar">
      <CircularProgress />
    </Box>
  </Box>
);

import {
  GET_TERMS_START,
  GET_TERMS_SUCCESS,
  GET_TERMS_ERROR,
} from './TermsContainerTypes';

const INITIAL_STATE = {
  terms: {},
  error: null,
  loading: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TERMS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload,
        loading: false,
      };
    case GET_TERMS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

import {
  GET_TERMS_START,
  GET_TERMS_SUCCESS,
  GET_TERMS_ERROR,
} from './TermsAndConditionsTypes';

const INITIAL_STATE = {
  data: {},
  error: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TERMS_START:
      return {
        ...state,
      };
    case GET_TERMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_TERMS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

import { certificatesValues, PRODUCT_CERTIFICATES } from 'services/config';
import {
  PRODUCT_DETAILS_START,
  GET_PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_ERROR,
  GET_FORM_FIELDS_SUCCESS,
  GET_PRODUCT_ATTRIBUTES_SUCCESS,
  GET_PRODUCT_BATCH_DETAILS_SUCCESS,
  RESET_PRODUCT_STATE,
  SET_SUBCATEGORIES_OPTIONS,
  IMAGES_SAVE,
  CERTIFICATE_SAVE,
  UPLOAD_SUCCESS,
  UPLOAD_MULTIPLE_SUCCESS,
  START_UPLOAD,
  CLEAR_ERRORS,
  DELETE_SUCCESS,
  PRODUCT_REJECT_SUCCESS,
  GET_PRODUCT_ATTRIBUTES_VIEW_MODE_SUCCESS,
  DELETE_DOCUMENT,
  DELETE_PHOTO,
  DELETE_CERTIFICATE,
} from './ProductFormTypes';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  productData: {},
  attributes: {},
  subcategories: [],
  documentsData: [],
  loadingUpload: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PRODUCT_DETAILS_START:
      return { ...state, loading: true, error: null };

    case START_UPLOAD:
      return { ...state, loadingUpload: true, error: null };

    case GET_PRODUCT_DETAILS_SUCCESS:
      const updatedData = {};

      Object.keys(action.payload.attributes).map((key) => {
        const newKey = `id${key}`;
        updatedData[newKey] = action.payload.attributes[key];
      });
      return {
        ...state,
        loading: false,
        productData: {
          ...action.payload,
          name: action.payload.product.name,
          attributes: updatedData,
          homogenization:
            action.payload.homogenization === 'YES' ? true : false,
          certificate:
            !!action.payload?.certificate &&
            action.payload.certificate.map((certificate: any) => {
              return {
                label: PRODUCT_CERTIFICATES[certificate],
                value: certificatesValues.find(
                  (item) => item.value === certificate
                )?.value,
              };
            }),
        },
        documentsData: action.payload.documents || [],
      };

    case GET_PRODUCT_BATCH_DETAILS_SUCCESS:
      const updatedAttibutes = {};

      Object.keys(action.payload.attributes).map((key) => {
        const newKey = `id${key}`;
        updatedAttibutes[newKey] = action.payload.attributes[key];
      });
      return {
        ...state,
        loading: false,
        productData: {
          ...action.payload,
          name: action.payload.name,
          categoryId: action.payload.categoryId,
          subcategoryId: action.payload.subcategoryId,
          dateCoaCreation: action.payload.dateCoaCreation || new Date(),
          attributes: updatedAttibutes,
          sources: action.payload.sources,
          homogenization:
            action.payload.homogenization === 'YES' ? true : false,
          certificate:
            !!action.payload.certificate &&
            action.payload?.certificate.map(
              (certificate: any) => PRODUCT_CERTIFICATES[certificate]
            ),
        },
      };

    case GET_FORM_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => ({
            label: item.name,
            value: item.id,
            subcategories: item.subcategories
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((subItem) => ({
                label: subItem.name,
                value: subItem.id,
              })),
          })),
        subcategories: [],
        sources: action.payload.sources.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        suppliers: action?.payload?.suppliers
          ?.sort((a, b) => a.fullName.localeCompare(b.fullName))
          ?.map((item) => ({
            label: item.fullName,
            value: item.id,
            companyId: item.companyId,
          })),
        certificate:
          (!!action.payload.certificate &&
            action.payload.certificate.map((item) => {
              return {
                label: item,
                value: item,
              };
            })) ||
          [],
      };

    case SET_SUBCATEGORIES_OPTIONS:
      return {
        ...state,
        subcategories: action.payload,
        attributes: {},
      };

    case PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload || true,
      };

    case GET_PRODUCT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        attributes: {
          sectionOne: action.payload.sectionOne.map((item) => ({
            ...item,
            name: item.name.replace('.', '.id'),
          })),
          sectionTwo: action.payload.sectionTwo.map((item) => ({
            ...item,
            name: item.name.replace('.', '.id'),
          })),
        },
      };

    case GET_PRODUCT_ATTRIBUTES_VIEW_MODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        attributes: {
          sectionOne: action.payload.sectionOne.map((item) => ({
            ...item,
            name: item.name.replace('.', '.id'),
            disabled: true,
          })),
          sectionTwo: action.payload.sectionTwo.map((item) => ({
            ...item,
            name: item.name.replace('.', '.id'),
            disabled: true,
          })),
        },
      };

    case UPLOAD_MULTIPLE_SUCCESS:
      return {
        ...state,
        loadingUpload: false,
        documentsData: [...state.documentsData, action.payload],
      };

    case UPLOAD_SUCCESS:
      const filteredData = state.documentsData.filter(
        (item) => item.productFileType !== action.payload.productFileType
      );

      return {
        ...state,
        loadingUpload: false,
        documentsData: [...filteredData, action.payload],
      };

    case IMAGES_SAVE:
      return {
        ...state,
        loading: false,
        productData: {
          ...state.productData,
          photos: action.payload,
        },
      };

    case CERTIFICATE_SAVE:
      return {
        ...state,
        loading: false,
        productData: {
          ...state.productData,
          certificates: action.payload,
        },
      };

    case DELETE_DOCUMENT:
      return {
        ...state,
        documentsData: state.documentsData.filter(
          (item) => item.id !== action.payload
        ),
      };

    case DELETE_PHOTO:
      return {
        ...state,
        productData: {
          ...state.productData,
          photos: state.productData.photos.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case DELETE_CERTIFICATE:
      return {
        ...state,
        productData: {
          ...state.productData,
          certificates: state.productData.certificates.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        isDone: true,
      };

    case PRODUCT_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case RESET_PRODUCT_STATE:
      return INITIAL_STATE;

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

import { LANGUAGES, LANGUAGES_TYPES, LOCAL_STORAGE_ITEMS } from './config';
import { ROLES } from './config';

export const saveAccount = (account) => {
  window.localStorage.setItem(
    LOCAL_STORAGE_ITEMS.ACCOUNT,
    JSON.stringify(account)
  );
};

export const getLocalAccount = () => {
  const account: any = window.localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCOUNT);
  return JSON.parse(account);
};

export const saveLocalLanguage = (language) => {
  localStorage.setItem(LOCAL_STORAGE_ITEMS.LANGUAGE, language);
};

export const getLocalLanguage = () => {
  const browserLanguage = navigator.language.slice(0, 2);
  const localLang = localStorage.getItem('language');

  if (LANGUAGES_TYPES.includes(browserLanguage)) {
    if (!!localLang && browserLanguage !== localLang) {
      return localLang;
    } else {
      localStorage.setItem('language', browserLanguage);
      return browserLanguage;
    }
  }
  return LANGUAGES.ENGLISH;
};

export const accountIsAdmin = () => {
  const account = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCOUNT) || '{}'
  );
  return (
    account.authorities && account.authorities.roles[0].name === ROLES.ADMIN
  );
};

export const accountIsSupplier = () => {
  const account = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCOUNT) || '{}'
  );
  return (
    account.authorities && account.authorities.roles[0].name === ROLES.SUPPLIER
  );
};

import API from 'services/api';

export const forgotPassword = async (mail: string) => {
  return await API.post('/auth/users/reset-password/init', { email: mail });
};

export const resetPasswordFinish = async (data: {
  key: string;
  newPassword: string;
}) => {
  return await API.post('/auth/users/reset-password/finish', data);
};

export const resetKeyValidity = async (key: { key: string }) => {
  return await API.post('/auth/users/check-reset-key', key);
};

export const changeAccountPassword = async (data, userId) => {
  return await API.post(`/auth/users/${userId}/update-password`, data);
};

export const getDetails = async (userId: number) => {
  return await API.get(`/auth/users/${userId}/profile-info`);
};

export const updateMobile = async (data) => {
  return API.patch(`/auth/users/change-mobile`, data);
};

export const verifyMobile = async (data) => {
  return await API.post(`/auth/users/mfa`, data);
};

export const verifyCode = async (data) => {
  return await API.post(`/auth/users/mfa-verify`, data);
};

export const disableTwoAuthentication = async () => {
  return await API.get(`/auth/users/mfa-disable`);
};

export const getCode = async (data) => {
  return await API.post(`/auth/users/mfa`, data);
};

export const getShortDetails = async (companyId: number) => {
  return await API.get(`/rec/companies/${companyId}/short-info`);
};

export const resendActivationLink = async (key) => {
  return await API.post('/account/resend-activation', {
    key,
  });
};

export const addVatNumber = async (vatNumber: any, branchId: any) => {
  return await API.put(`/customers/${branchId}/vat-number`, {
    vatNumber: vatNumber,
  });
};

export const registerUser = async (data) => {
  return await API.post('/auth/users/register', data);
};

export const companyUser = async (data) => {
  return await API.post('/rec/companies/', data);
};

export const getCompanyInformation = async (companyId) => {
  return await API.get(`/rec/companies/invite?code=${companyId}`);
};

export const sendActivationCode = async (email: string) => {
  return await API.post('/auth/users/send-activation-code', {
    email,
  });
};

export const validateActivationCode = (code: string) => {
  return API.post('/auth/users/validate-code', {
    code,
  });
};

export const PRODUCT_DETAILS_START = 'product-details-start';
export const PRODUCT_DETAILS_ERROR = 'product-details-error';
export const GET_PRODUCT_DETAILS_SUCCESS = 'get-product-details-success';
export const GET_FORM_FIELDS_SUCCESS = 'get-form-fields-success';
export const GET_PRODUCT_ATTRIBUTES_SUCCESS = 'get-product-attributes-success';
export const GET_PRODUCT_BATCH_DETAILS_SUCCESS =
  'get-product-batch-details-success';
export const RESET_PRODUCT_STATE = 'reset-product-state';
export const SET_SUBCATEGORIES_OPTIONS = 'set-subcategories-options';
export const IMAGES_SAVE = 'products-images-save';
export const CERTIFICATE_SAVE = 'products-certificate-save';
export const UPLOAD_DATA = 'upload-data-products';
export const RESET_UPLOAD = 'reset-upload';
export const START_UPLOAD = 'start-upload';
export const UPLOAD_MULTIPLE_SUCCESS = 'upload-multiple-success';
export const UPLOAD_SUCCESS = 'upload-success';
export const CLEAR_ERRORS = 'products-details-clear-error';
export const DELETE_SUCCESS = 'products-details-delete';
export const PRODUCT_REJECT_SUCCESS = 'product-reject-success';
export const GET_PRODUCT_ATTRIBUTES_VIEW_MODE_SUCCESS =
  'get-product-attributes-view-mode-success';
export const DELETE_DOCUMENT = 'product-delete-documents';
export const DELETE_PHOTO = 'product-delete-photo';
export const DELETE_CERTIFICATE = 'product-delete-certificate';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';
import { InputError } from 'components';
import {
  getCodeVerification,
  verifyCode,
} from 'containers/admin/Profile/ProfileActions';
import { formatTime } from 'services/calculateDate';
import { MFA_SWITCH_SUCCESS } from 'containers/admin/Profile/ProfileTypes';

import './VerificationDialog.scss';

type VerificationDialogProps = {
  verificationDialog: boolean;
  handleVerificationDialogClose: () => void;
  mobileNumber?: string;
};

export const VerificationDialog: React.FC<VerificationDialogProps> = ({
  verificationDialog,
  handleVerificationDialogClose,
  mobileNumber,
}) => {
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();

  const methods: any = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = methods;

  const submitForm = (data) => {
    const success = () => {
      reset();
      handleVerificationDialogClose();
      dispatch({
        type: MFA_SWITCH_SUCCESS,
        payload: { switch: true, successMessage: 'enabledTwoAuth' },
      });
    };
    dispatch(verifyCode(data, success));
  };

  useEffect(() => {
    let timer;
    if (isResendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const handleResendCode = () => {
    dispatch(getCodeVerification({ mobileNumber: mobileNumber }));
    setIsResendDisabled(true);
    setCountdown(120);
  };

  return (
    <Dialog
      className="verification-dialog"
      open={verificationDialog}
      maxWidth={'xs'}
      onClose={() => {
        handleVerificationDialogClose();
        dispatch({
          type: MFA_SWITCH_SUCCESS,
          payload: { switch: false, successMessage: null },
        });
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="verification-dialog-title">
        <Typography variant="body1" component="span">
          {t('2faDialogTitle')}
        </Typography>

        <IconButton
          className="close-button"
          aria-label="close"
          onClick={() => {
            reset();
            handleVerificationDialogClose();
            dispatch({
              type: MFA_SWITCH_SUCCESS,
              payload: { switch: false, successMessage: null },
            });
          }}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(submitForm)}>
        <DialogContent>
          <Typography variant="subtitle2">
            {t('2faDialogDescription')} {mobileNumber}
          </Typography>

          <TextField
            {...register('code', {
              required: true,
              validate: (value) => value.length === 6,
            })}
            margin="normal"
            fullWidth
            variant="outlined"
            label={t('verificationCode')}
            inputProps={{
              inputMode: 'number',
              maxLength: 6,
              onKeyPress: (e: any) => {
                const isNumber = /\d/.test(e.key);
                const currentLength = e.target.value.length;
                if (!isNumber || currentLength >= 6) {
                  e.preventDefault();
                }
              },
            }}
            helperText={
              errors.code && <InputError message={t('enterValidCode')} />
            }
          />
          <Typography
            className="resend-code"
            variant="subtitle1"
            color={!isResendDisabled ? 'primary' : ''}
            onClick={() => !isResendDisabled && handleResendCode()}
            style={{ cursor: isResendDisabled ? 'not-allowed' : 'pointer' }}
          >
            {t('resendCode')}
            {isResendDisabled && (
              <>
                <AccessTimeIcon className="icon-access-time" />{' '}
                {formatTime(countdown)}
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {t('verify')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  TOKEN_VALID,
  TOKEN_ERROR,
  RESEND_ACTIVATION_LINK_SUCCESS,
} from './ResetPasswordTypes';
import { ERROR_MESSAGE } from './ResetPasswordView';

export const INITIAL_STATE = {
  loading: false,
  error: null,
  activeForm: true,
  errorMessage: null,
  activationLinkSent: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_PASSWORD_START:
      return { ...state, loading: true };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false };

    case RESET_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload };

    case TOKEN_VALID:
      return { ...state, loading: false, activeForm: true };

    case RESEND_ACTIVATION_LINK_SUCCESS:
      return { ...state, loading: false, activationLinkSent: true };

    case TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        activeForm: false,
        error: action.payload,
        errorMessage:
          action.payload.includes('KeyNotFoundException') ||
          action.payload.includes('resetKeyNotFound') ||
          action.payload.includes('EXPIRED')
            ? ERROR_MESSAGE.LINK_EXPIRED
            : action.payload,
      };

    default:
      return state;
  }
};

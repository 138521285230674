import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { InputError } from 'components';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useDispatch } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  getCodeVerification,
  updateMobile,
  verifyCode,
  verifyMobile,
} from 'containers/admin/Profile/ProfileActions';
import { UPDATE_MOBILE_NUMBER } from 'containers/admin/Profile/ProfileTypes';
import { formatTime } from 'services/calculateDate';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './MobileNumberDialog.scss';

type MobileNumberDialogProps = {
  mobileNumberDialog: boolean;
  handleMobileDialogClose: () => void;
  mobileNumber?: string;
  isMfaEnabled?: Boolean;
};

export const MobileNumberDialog: React.FC<MobileNumberDialogProps> = ({
  mobileNumberDialog,
  handleMobileDialogClose,
  mobileNumber,
  isMfaEnabled,
}) => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const isMFA = isMfaEnabled;

  const methods: any = useForm({
    mode: 'onSubmit',
  });

  const {
    formState: { errors },
    register,
    reset,
    control,
    getValues,
    setValue,
    watch,
  } = methods;

  const [twoAuth, setTwoAuth] = useState(false);

  const handleNextButton = () => {
    const success = () => {
      setTwoAuth(true);
    };
    dispatch(
      verifyMobile({ mobileNumber: getValues('mobileNumber') }, success)
    );
  };

  const handleUpdateMobile = (data) => {
    const success = () => {};
    dispatch(updateMobile(data, success));
  };

  const handleVerifyMobileAndCode = (data) => {
    const success = () => {
      dispatch({
        type: UPDATE_MOBILE_NUMBER,
        payload: getValues('mobileNumber'),
      });
      reset();
      handleMobileDialogClose();
      setTwoAuth(false);
    };

    dispatch(verifyCode(data, success));
  };

  useEffect(() => {
    let timer;
    if (isResendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled]);

  useEffect(() => {
    setValue('mobileNumber', mobileNumber);
    if (countdown === 0) {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const handleResendCode = () => {
    dispatch(getCodeVerification({ mobileNumber: getValues('mobileNumber') }));
    setIsResendDisabled(true);
    setCountdown(120);
  };

  const handleChangeTel = (e) => {
    setValue('mobileNumber', e);
  };

  return (
    <Dialog
      className="mobile-number-dialog"
      open={mobileNumberDialog}
      maxWidth={'xs'}
      onClose={() => {
        reset();
        handleMobileDialogClose();
        setTwoAuth(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="mobile-number-title">
        {twoAuth ? (
          <Typography variant="body1" component="span">
            {t('2faDialogTitle')}
          </Typography>
        ) : (
          <Typography variant="body1" component="span">
            {t('mobileNumberDialogTitle')}
          </Typography>
        )}

        <IconButton
          className="close-button"
          aria-label="close"
          onClick={() => {
            reset();
            handleMobileDialogClose();
            setTwoAuth(false);
          }}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={methods.handleSubmit(async (data, event: any) => {
          const isFormValid = await methods.trigger();
          if (isFormValid) {
            event.nativeEvent.submitter.id === 'updateMobileNumber'
              ? handleUpdateMobile({ mobileNumber: data.mobileNumber })
              : event.nativeEvent.submitter.id === 'verifyMobileNumber'
              ? handleVerifyMobileAndCode({ code: data.code, mobileNumber: data.mobileNumber })
              : handleNextButton();
          }
        })}
      >
        <DialogContent>
          {twoAuth ? (
            <Typography variant="subtitle2">
              {t('2faDialogDescription')} {getValues('mobileNumber')}
            </Typography>
          ) : (
            ''
          )}

          {twoAuth ? (
            <>
              <TextField
                {...register('code', {
                  required: true,
                  validate: (value) => value.length === 6,
                })}
                className="login-input"
                margin="normal"
                fullWidth
                variant="outlined"
                label={t('verificationCode')}
                inputProps={{
                  inputMode: 'number',
                  maxLength: 6,
                  onKeyPress: (e: any) => {
                    const isNumber = /\d/.test(e.key);
                    const currentLength = e.target.value.length;
                    if (!isNumber || currentLength >= 6) {
                      e.preventDefault();
                    }
                  },
                }}
                helperText={
                  errors.code && <InputError message={t('enterValidCode')} />
                }
              />
              <Typography
                className="resend-code"
                variant="subtitle1"
                color={!isResendDisabled ? 'primary' : ''}
                onClick={() => !isResendDisabled && handleResendCode()}
                style={{ cursor: isResendDisabled ? 'not-allowed' : 'pointer' }}
              >
                {t('resendCode')}
                {isResendDisabled && (
                  <>
                    <AccessTimeIcon className="icon-access-time" />{' '}
                    {formatTime(countdown)}
                  </>
                )}
              </Typography>
            </>
          ) : (
            <Controller
              name="mobileNumber"
              control={control}
              rules={{
                validate: (value) => matchIsValidTel(value),
              }}
              render={({ field: { ref, ...fieldProps }, fieldState }) => (
                <div style={{ position: 'relative' }}>
                  <ArrowDropDownIcon className="arrow-mui-tel-input" />
                  <MuiTelInput
                    {...fieldProps}
                    ref={ref}
                    defaultCountry={'DE'}
                    inputProps={{
                      placeholder: `${t('phoneNumber')}`,
                      className: fieldState.invalid
                        ? 'invalid-phone-input'
                        : '',
                    }}
                    onChange={(e) => handleChangeTel(e)}
                    forceCallingCode={true}
                    helperText={
                      fieldState.invalid ? (
                        <InputError message={t('enterValidPhoneNumber')} />
                      ) : (
                        ''
                      )
                    }
                    error={fieldState.invalid}
                  />
                </div>
              )}
              defaultValue={mobileNumber}
            />
          )}
        </DialogContent>
        <DialogActions>
          {isMFA ? (
            twoAuth ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                id="verifyMobileNumber"
              >
                {t('verify')}
              </Button>
            ) : (
              <Button
                id="nextVerifyCode"
                type="submit"
                variant="contained"
                color="primary"
                disabled={mobileNumber === watch('mobileNumber')}
              >
                {t('next')}
              </Button>
            )
          ) : (
            <Button
              type="submit"
              id="updateMobileNumber"
              variant="contained"
              color="primary"
              disabled={mobileNumber === watch('mobileNumber')}
            >
              {t('submit')}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

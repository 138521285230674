import React from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { CustomModal } from 'components/CustomModal/CustomModal';
import { InputError } from 'components/InputError/InputErrors';
import { Controller, useForm } from 'react-hook-form';
import { emailRegex } from 'services/config';
import { useTranslation } from 'react-i18next';
import { MultiDropdownButton } from 'components/MultiDropdownButton/MultiDropdownButton';

type InviteCustomerModalProps = {
  isVisible: boolean;
  companies: any[];
  errorMessage: string;
  handleSubmit: (data: any) => void;
  handleClose: () => void;
};

export const InviteCustomerModal = (props: InviteCustomerModalProps) => {
  const {
    getValues,
    control,
    reset,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  const { t } = useTranslation('suppliers');
  const { isVisible, companies, errorMessage, handleSubmit, handleClose } =
    props;

  const inviteCustomer = async (formValidation: boolean) => {
    if (formValidation) {
      handleSubmit(getValues());
      reset();
    }
  };

  return (
    <CustomModal
      title={t('inviteCustomer')}
      modalOpen={isVisible}
      handleModalClose={() => {
        handleClose();
        reset();
      }}
      handleModalSubmit={() => {}}
      modalContent={
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: emailRegex,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="email"
                    label={t('emailAddress')}
                    data-qa="invite-customer-email"
                    placeholder={t('emailAddress')}
                    required
                    name="email"
                    error={!!errors.email}
                    inputProps={{
                      name: 'email',
                      maxLength: 255,
                    }}
                    helperText={
                      errors.email && (
                        <InputError
                          message={
                            errors.email.type === 'pattern'
                              ? t('enterValidEmail')
                              : t('requiredField')
                          }
                        />
                      )
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="companyId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MultiDropdownButton
                    label={t('company')}
                    multiple={false}
                    options={companies || []}
                    errorKey={errors.companyId && t('requiredField')}
                    name="companyId"
                    placeholder={t('company')}
                    dataQA="invite-customer-companyId"
                    value={getValues('companyId')}
                    onChange={(event, value) =>
                      setValue('companyId', value.value)
                    }
                    limitTags={4}
                    required={true}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      }
      modalActions={
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={() => {
                handleClose();
                reset();
              }}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={async () => {
                const validation = await trigger();
                inviteCustomer(validation);
              }}
            >
              {t('sendInvitation')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Typography, Box, Link } from '@mui/material';
import { APP_REDIRECTS, passwordRegex } from 'services/config';
import { IntroCard, InputError, Loading, LanguageSwitcher } from 'components';
import { checkKey, resendActivationLink } from './ResetPasswordActions';

import './ResetPassword.scss';

export enum ERROR_MESSAGE {
  INVALID_RESET = 'INVALID_RESET',
  INVALID_ACTIVATION = 'INVALID_ACTIVATION',
  LINK_EXPIRED = 'LINK_EXPIRED',
}

export const ResetPasswordView = ({ clearPassword }) => {
  const backgroundImage =
    process.env.REACT_APP_API_URL_IMAGES + '/login-background.png';
  const logoImage = process.env.REACT_APP_API_URL_IMAGES + '/login-logo.svg';
  const dispatch = useDispatch();
  const { t } = useTranslation('resetPassword');
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const resPassword = useSelector((state: any) => state.resetPassword);
  const { activeForm, errorMessage } = resPassword;
  const route = useParams();

  const submitForm = (data: any) => {
    clearPassword({
      key: route.key || '',
      newPassword: data.password,
    });
  };

  useEffect(() => {
    const keyCheck = route.key || '';

    dispatch(checkKey({ key: keyCheck }));
  }, []);

  const requestNewActivationLink = async () => {
    await dispatch(resendActivationLink(route.key || ''));
  };

  return (
    <>
      {resPassword.loading ? (
        <Loading />
      ) : (
        <Box
          className="reset-password-page"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <IntroCard title={t('titleHeader')} subtitle={t('subTitleHeader')}>
            <Box className="reset-form-wrap">
              <LanguageSwitcher />
              <div
                className="reset-logo"
                style={{
                  backgroundImage: `url(${logoImage})`,
                }}
              />

              {activeForm ? (
                <form onSubmit={handleSubmit(submitForm)}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="helper-text"
                  >
                    {t('passwordHelperText')}
                  </Typography>
                  <TextField
                    {...register('password', {
                      required: true,
                      pattern: passwordRegex,
                    })}
                    className="reset-input"
                    margin="normal"
                    type="password"
                    variant="outlined"
                    label={t('newPassword')}
                    data-qa="rp-new-password"
                    placeholder={t('newPassword')}
                    inputProps={{
                      name: 'password',
                    }}
                    helperText={
                      errors.password && (
                        <InputError
                          message={
                            errors.password.type === 'required'
                              ? t('passwordIsRequired')
                              : t('passwordStrengthError')
                          }
                        />
                      )
                    }
                  />

                  <TextField
                    {...register('repeatPassword', {
                      required: true,
                      validate: (value) => value === getValues().password,
                    })}
                    className="reset-input"
                    margin="normal"
                    type="password"
                    variant="outlined"
                    label={t('repeatPassword')}
                    data-qa="rp-repeat-password"
                    placeholder={t('repeatPassword')}
                    inputProps={{
                      name: 'repeatPassword',
                    }}
                    helperText={
                      errors.repeatPassword && (
                        <InputError message={t('passwordsAreNotTheSame')} />
                      )
                    }
                  />
                  <Button
                    type="submit"
                    className="reset-button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    data-qa="rp-submit-btn"
                  >
                    {t('resetPassword')}
                  </Button>
                  <Link
                    className="forgot-link"
                    href={APP_REDIRECTS.LOGIN}
                    underline="none"
                  >
                    {t('linkBackToLogin')}
                  </Link>
                </form>
              ) : (
                <>
                  {!resPassword.activationLinkSent ? (
                    <>
                      <Typography variant="subtitle1" className="error-message">
                        {t(`${errorMessage}`)}
                      </Typography>
                      {ERROR_MESSAGE.INVALID_ACTIVATION === errorMessage && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={requestNewActivationLink}
                        >
                          {t(`INVALID_ACTIVATION_REQUEST_NEW_LINK`)}
                        </Button>
                      )}
                      {(ERROR_MESSAGE.INVALID_RESET === errorMessage ||
                        errorMessage === ERROR_MESSAGE.LINK_EXPIRED) && (
                        <Link href={APP_REDIRECTS.LOGIN} underline="none">
                          &larr; {t('backToLogin')}
                        </Link>
                      )}
                    </>
                  ) : (
                    <Typography variant="subtitle1">
                      {t('activationLinkSent')}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </IntroCard>
        </Box>
      )}
    </>
  );
};

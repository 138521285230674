import * as AccountRepository from 'repository/account';
import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  UPDATE_MOBILE_NUMBER,
  VERIFY_MOBILE_NUMBER,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_ERROR,
  PASSWORD_CHANGE_SUCCESS,
  MFA_SWITCH_SUCCESS,
} from './ProfileTypes';

export const getProfile = (userId: number, isSupplier: boolean) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PROFILE,
    });

    try {
      const details = await AccountRepository.getDetails(userId);
      dispatch({
        type: GET_PROFILE_DETAILS_SUCCESS,
        payload: details.data,
      });

      if (isSupplier) {
        const shortDetails = await AccountRepository.getShortDetails(
          details.data.companyId
        );

        dispatch({
          type: GET_PROFILE_DETAILS_SUCCESS,
          payload: shortDetails.data,
        });
      }
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const updatePassword = (
  data: any,
  userId: number,
  callback: () => void
) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PROFILE,
    });

    try {
      await AccountRepository.changeAccountPassword(data, userId);
      dispatch({
        type: PASSWORD_CHANGE_SUCCESS,
      });
      callback();
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const updateMobile = (data, success) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PROFILE,
    });

    try {
      await AccountRepository.updateMobile(data);
      dispatch({
        type: UPDATE_MOBILE_NUMBER,
        payload: data.mobileNumber,
      });
      success();
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const verifyMobile = (data, success) => {
  return async (dispatch) => {
    try {
      await AccountRepository.verifyMobile(data);
      dispatch({
        type: VERIFY_MOBILE_NUMBER,
      });
      success();
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const verifyCode = (data, success) => {
  return async (dispatch) => {
    try {
      await AccountRepository.verifyCode(data);
      success();
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const disableTwoAuth = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_PROFILE,
    });

    try {
      await AccountRepository.disableTwoAuthentication();
      dispatch({
        type: MFA_SWITCH_SUCCESS,
        payload: { switch: false, successMessage: 'disabledTwoAuth' },
      });
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const getCodeVerification = (data) => {
  return async (dispatch) => {
    try {
      await AccountRepository.getCode(data);
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: GET_PROFILE_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const clearErrors = () => ({
  type: GET_PROFILE_ERROR,
  payload: null,
});

import {
  PRODUCTS_DETAILS_START,
  GET_CONTACTS_SUCCESS,
  GET_QR_SUCCESS,
  PRODUCTS_DETAILS_ERROR,
} from './SendProductsDetailsTypes';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: {
    contacts: [],
    qrImage: [],
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PRODUCTS_DETAILS_START:
      return {
        ...state,
        loading: true,
      };

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          contacts: action.payload.map((user: any) => {
            return {
              value: user.id,
              label: user.name,
              email: user.email,
            };
          }),
        },
      };

    case PRODUCTS_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_QR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          qrImage: action.payload,
        },
      };

    default:
      return state;
  }
};

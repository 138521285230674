import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'components/Navigation/Navigation';

type TableBottomPaginationProps = {
  locale: string;
  label?: string;
  selectValue: number;
  onChange: (any) => void;
  itemsPerPage?: number[];
  pagination: any;
  goToPage: (any) => void;
  isVisible?: boolean;
};

export const TableBottomPagination: React.FC<TableBottomPaginationProps> = ({
  locale,
  label,
  selectValue,
  onChange,
  itemsPerPage = [10, 25, 50],
  pagination,
  goToPage,
  isVisible = true,
}) => {
  const { t } = useTranslation(locale);
  return (
    <Box className="bottom-toolbar">
      {isVisible && (
        <Box className="per-page">
          {label && (
            <Typography variant="subtitle2" component="label">
              {t(label)}
            </Typography>
          )}
          <Select value={selectValue} onChange={onChange} variant="standard">
            {(itemsPerPage || []).map((item, index) => (
              <MenuItem value={item} key={item + index}>
                <Typography variant="subtitle2" component="label">
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <Box className="grow"></Box>

      {pagination?.last > 1 && (
        <Navigation pagination={pagination} goToPage={goToPage} />
      )}
    </Box>
  );
};

import { Box } from '@mui/material';
import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export const PublicLayout: FC = () => {
  return (
    <Box className="public">
      <Box className="content">
        <Suspense fallback="loading..">
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};

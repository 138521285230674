import React from 'react';
import { Grid, Typography, Paper, Box, Link, useTheme } from '@mui/material';
import { meraxisGroupLink } from 'services/config';

import './IntroCard.scss';
import { url } from 'inspector';

type IntroCardProps = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
};

export const IntroCard: React.FC<IntroCardProps> = ({
  title,
  subtitle,
  children,
}) => {
  const backgroundImage =
    process.env.REACT_APP_API_URL_IMAGES + '/login-card-background.png';

  return (
    <Grid
      className="intro-card"
      justifyContent="center"
      alignContent="center"
      container
    >
      <Grid item xs={12} sm={8} lg={6} xl={6}>
        <Paper className="intro-paper">
          <Grid container className="intro-container">
            <Grid
              item
              xs={12}
              lg={6}
              xl={6}
              className="intro-intro"
              style={{
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <Box className="intro-message">
                <Typography
                  variant="h3"
                  className="intro-title"
                  color="secondary"
                >
                  {title}
                </Typography>
                <Box mt={2}>
                  <Typography variant="overline" color="secondary">
                    {subtitle}
                  </Typography>
                </Box>
              </Box>
              <Box className="official-url">
                <Link href="https://www.meraxis-group.com/">
                  <Typography variant="overline" color="secondary">
                    {meraxisGroupLink}
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} xl={6} className="content-section">
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { Menu, MenuItem } from '@mui/material';
import { getLocalLanguage, saveLocalLanguage } from 'services/account';
import { LANGUAGES, LANGUAGES_TYPES } from 'services/config';

import './LanguageSwitcher.scss';

type LanguageSwitcherProps = {
  color?:
    | 'action'
    | 'disabled'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'warning'
    | undefined;
};

export const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const [language, setLanguage] = useState(getLocalLanguage());
  const { i18n } = useTranslation();
  const { t } = useTranslation('languageSwitcher');
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const languagesAvailable = [
    {
      name: t(`english`),
      value: LANGUAGES.ENGLISH.toLowerCase(),
    },
    {
      name: t('german'),
      value: LANGUAGES.GERMAN.toLowerCase(),
    },
    {
      name: t('french'),
      value: LANGUAGES.FRENCH.toLowerCase(),
    },
  ];

  useEffect(() => {
    const lang = getLocalLanguage();
    if (LANGUAGES_TYPES.includes(lang)) {
      i18n.changeLanguage(lang), setLanguage(lang), saveLocalLanguage(lang);
    } else {
      i18n.changeLanguage(LANGUAGES.ENGLISH.toLowerCase()),
        setLanguage(LANGUAGES.ENGLISH.toLowerCase()),
        saveLocalLanguage(LANGUAGES.ENGLISH.toLowerCase());
    }
  }, [i18n]);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    saveLocalLanguage(newLanguage);
    handleClose();
  };

  return (
    <div className="language-switch">
      <IconButton
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <LanguageIcon color={props.color || 'info'} />
      </IconButton>
      <Menu
        className={'language-switch-menu user-dropdown'}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(languagesAvailable || []).map((languageItem) => (
          <MenuItem
            key={languageItem.value}
            onClick={() => changeLanguage(languageItem.value)}
            className={
              language === languageItem.value
                ? 'switcher-item active'
                : 'switcher-item'
            }
          >
            {languageItem.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

import Axios from 'axios';
import { APP_REDIRECTS } from './config';
import {
  isAuth,
  isTwoAuth,
  logoutUser,
  saveUserRefreshToken,
  saveUserToken,
  userRefreshToken,
} from './auth';

const instance: any = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = isAuth();
    const tokenAuth = isTwoAuth();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (tokenAuth) {
      config.headers['Authorization'] = `Bearer ${tokenAuth}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const originalConfig = error.config;

    if ([403].includes(error.response.status) && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const rs = await instance.post('/auth/refresh-authenticate', {
          refreshToken: userRefreshToken(),
        });

        const { idToken, refreshToken } = rs.data;

        saveUserToken(idToken);
        saveUserRefreshToken(refreshToken);

        return instance(originalConfig);
      } catch (error) {
        logoutUser();
        window.location.href = APP_REDIRECTS.LOGIN;
        return Promise.reject(error);
      }
    }

    if ([401].includes(error.response.status)) {
      const isLoginReq =
        error.response.config.url.match(/authenticate/g) ||
        error.response.config.url.match(/api\/authenticate/g);

      if (!isLoginReq || !isLoginReq.length) {
        logoutUser();
        window.location.href = APP_REDIRECTS.LOGIN;
      }
    }

    return Promise.reject(error);
  }
);

export default instance;

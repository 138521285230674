import * as NotificationsRepository from './../../repository/notifications';
import { GET_NOTIFICATIONS, NOTIFICATIONS_ERROR } from './NotificationsTypes';

export const getNotifications = (userId: number, page: number) => {
  return async (dispatch) => {
    try {
      const response = await NotificationsRepository.getNotifications(
        userId,
        page
      );

      dispatch({
        type: GET_NOTIFICATIONS,
        payload: {
          ...response.data,
          page,
          unreadNotif: response.headers['rec-total-unread'],
        },
      });
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const markNotificationAsRead = (
  notificationId: number,
  redirectPath: string
) => {
  return async (dispatch) => {
    try {
      await NotificationsRepository.markNotificationAsRead(notificationId);
      window.location.replace(redirectPath);
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const clearNotifications = (userId, page = 0) => {
  return async (dispatch) => {
    try {
      await NotificationsRepository.clearNotifications(userId);
      const response = await NotificationsRepository.getNotifications(
        userId,
        page
      );

      dispatch({
        type: GET_NOTIFICATIONS,
        payload: {
          ...response.data,
          page,
          unreadNotif: response.headers['rec-total-unread'],
        },
      });
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

export const deleteNotification = (
  notificationId: number,
  userId: number,
  page: number
) => {
  return async (dispatch) => {
    try {
      const response = await NotificationsRepository.deleteNotification(
        notificationId
      );

      dispatch(getNotifications(userId, page));
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: errorData.detail || errorData.title,
      });
    }
  };
};

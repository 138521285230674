import React from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { Arrow } from 'components';

import './Navigation.scss';

type NavigationProps = {
  pagination: {
    current: number;
    last: number;
  };
  goToPage: Function;
};

export const Navigation: React.FC<NavigationProps> = ({
  pagination,
  goToPage,
}) => {
  const { current, last } = pagination;
  return (
    <div className="navigation">
      <ButtonGroup
        color="primary"
        size="small"
        aria-label="outlined primary button group"
      >
        {current !== 1 && (
          <Button onClick={() => goToPage(current - 2)} className="nav-item">
            <Arrow direction="left" />
          </Button>
        )}
        {current - 3 > 0 && (
          <Button onClick={() => goToPage(current - 4)} className="nav">
            {current - 3}
          </Button>
        )}
        {current - 2 > 0 && (
          <Button onClick={() => goToPage(current - 3)} className="nav">
            {current - 2}
          </Button>
        )}
        {current - 1 > 0 && (
          <Button onClick={() => goToPage(current - 2)} className="nav">
            {current - 1}
          </Button>
        )}
        <Button
          onClick={() => goToPage(current - 1)}
          className="nav nav-active"
        >
          {current}
        </Button>
        {current + 1 <= last && (
          <Button onClick={() => goToPage(current)} className="nav">
            {current + 1}
          </Button>
        )}
        {current + 2 <= last && (
          <Button onClick={() => goToPage(current + 1)} className="nav">
            {current + 2}
          </Button>
        )}
        {current + 3 <= last && (
          <Button onClick={() => goToPage(current + 2)} className="nav">
            {current + 3}
          </Button>
        )}

        {last && last !== current && (
          <Button onClick={() => goToPage(current)} className="nav-item">
            <Arrow direction="right" />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

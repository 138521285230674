import { format } from 'date-fns';
import { ALIGN } from 'services/config';
import {
  COMPANIES_SUCCESS,
  GET_SUPPLIER_SUCCESS,
  SET_PAGINATION,
  GET_SUPPLIERS_SUCCESS,
  GET_COMPANY_INFORMATION,
  SUPPLIERS_START,
  RESET_SUPPLIERS_TABLE,
  RESET_SUPPLIER_PRODUCTS_TABLE,
  SUPPLIER_ERROR,
  CLEAR_ERRORS,
} from './SuppliersTypes';

const tableColumns = [
  {
    label: 'fullName',
    sorterKey: 'fullName',
    align: ALIGN.LEFT,
    isVisible: true,
    sorter: true,
    width: '15%',
  },
  {
    label: 'companyName',
    sorterKey: 'companyName',
    align: ALIGN.LEFT,
    isVisible: true,
    sorter: true,
    width: '25%',
  },
  {
    label: 'createdDate',
    sorterKey: 'createdDate',
    align: ALIGN.LEFT,
    isVisible: true,
    sorter: true,
    width: '25%',
  },
  {
    label: 'companyCountry',
    sorterKey: 'companyCountry',
    align: ALIGN.LEFT,
    isVisible: true,
    sorter: true,
    width: '20%',
  },
  {
    label: 'action',
    sorter: false,
    sorterKey: '',
    align: ALIGN.LEFT,
    isVisible: true,
    width: '15%',
  },
];

const tableVisibleColumns = [
  'fullName',
  'companyName',
  'createdDate',
  'companyCountry',
  'action',
];

const exportTableColumns = [
  'fullName',
  'companyName',
  'createdDate',
  'companyCountry',
];

const INITIAL_STATE = {
  tableColumns: tableColumns,
  tableVisibleColumns: tableVisibleColumns,
  exportTableColumns: exportTableColumns,
  data: null,
  pagination: null,
  productsPagination: null,
  companies: [],
  supplierData: null,
  companyData: null,
  products: null,
  loading: false,
  error: null,
  notification: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SUPPLIERS_START:
      return {
        ...state,
        loading: true,
      };

    case GET_SUPPLIERS_SUCCESS:
      const tableData = action.payload;

      tableData.content.map((item) => {
        item['createdDate'] = format(
          new Date(item['createdDate']),
          'dd/MM/yyyy'
        );
      });
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };

    case COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload.map((item) => {
          return { value: item.id, label: item.companyName };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
      };

    case GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        supplierData: {
          ...state.supplierData,
          ...action.payload,
        },
      };

    case GET_COMPANY_INFORMATION:
      return {
        ...state,
        loading: false,
        error: false,
        companyData: action.payload,
      };

    case RESET_SUPPLIERS_TABLE:
      return {
        ...state,
        data: INITIAL_STATE.data,
        pagination: INITIAL_STATE.pagination,
      };

    case RESET_SUPPLIER_PRODUCTS_TABLE:
      return {
        ...state,
        products: INITIAL_STATE.products,
        supplierData: INITIAL_STATE.supplierData,
        companyData: INITIAL_STATE.companyData,
      };

    case SUPPLIER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default: {
      return state;
    }
  }
};

export const isDateWithin12Hours = (customDate) => {
  const now = new Date();
  const date = new Date(customDate);
  const diffInMilliseconds = date.getTime() - now.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours < 12;
};

export const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

export const calculateTimeAgo = (notificationDate) => {
  const currentDatetime = new Date();
  const notificationDatetime = new Date(notificationDate);

  const timeDifference =
    currentDatetime.getTime() - notificationDatetime.getTime();
  const secondsAgo = Math.floor(timeDifference / 1000);

  if (secondsAgo < 60) {
    return 'Just now';
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
  } else if (secondsAgo < 604800) {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
  } else {
    const weeksAgo = Math.floor(secondsAgo / 604800);
    return `${weeksAgo} ${weeksAgo === 1 ? 'week' : 'weeks'} ago`;
  }
};

export const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
    2,
    '0'
  )}`;
};

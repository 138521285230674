export const SUPPLIERS_START = 'SUPPLIERS_START';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const SUPPLIER_ERROR = 'SUPPLIER_ERROR';
export const SET_PAGINATION = 'SET_PAGINATION';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION';
export const GET_SUPPLIER_STATS = 'GET_SUPPLIER_STATS';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const RESET_SUPPLIERS_TABLE = 'RESET_SUPPLIERS_TABLE';
export const RESET_SUPPLIER_PRODUCTS_TABLE = 'RESET_SUPPLIERS_PRODUCTS_TABLE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

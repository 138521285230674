import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { LANGUAGES, LANGUAGES_TYPES } from 'services/config';

const browserLanguage = navigator.language.slice(0, 2);
const localLanguage = localStorage.getItem('language');

const language = LANGUAGES_TYPES.includes(browserLanguage)
  ? !!localLanguage && localLanguage !== browserLanguage
    ? localLanguage
    : browserLanguage
  : LANGUAGES.ENGLISH.toLowerCase();

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGES.ENGLISH.toLowerCase(),
    interpolation: { escapeValue: false },
    lng: language,
    debug: false,
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${new Date().toString()}`,
      addPath: 'locales/add/{{lng}}/{{ns}}',
    },
  });

export default i18next;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes/routes';
import { ThemeProvider } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { defaultTheme } from './themes/default';
import store from 'store/store';

import './i18n';
import './index.scss';
import { CacheProvider } from '@emotion/react';
import createCache  from '@emotion/cache';

let theme = createTheme();
theme = responsiveFontSizes(defaultTheme);

const cache = createCache({
  key: 'meraxis',
  nonce: process.env.REACT_APP_API_NONCE,
  prepend: true,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ReduxProvider store={store}>
    <CacheProvider value={cache}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
    </CacheProvider>
  </ReduxProvider>
);

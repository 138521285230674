import { LOCAL_STORAGE_ITEMS } from './config';

export const saveUserToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE_ITEMS.TOKEN, token);
};

export const saveUserRefreshToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, token);
};

export const logoutUser = () => {
  const selectedLangugage = localStorage.getItem('language') || 'en';
  localStorage.clear();
  localStorage.setItem('language', selectedLangugage);
};

export const isAuth = () => {
  if (
    localStorage.getItem(LOCAL_STORAGE_ITEMS.TOKEN) &&
    localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCOUNT) &&
    localStorage.getItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN)
  ) {
    return localStorage.getItem(LOCAL_STORAGE_ITEMS.TOKEN);
  }
  return false;
};

export const isTwoAuth = () => {
  if (
    localStorage.getItem(LOCAL_STORAGE_ITEMS.TOKEN) &&
    localStorage.getItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN)
  ) {
    return localStorage.getItem(LOCAL_STORAGE_ITEMS.TOKEN);
  }
  return false;
};

export const userRefreshToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN);
};

import {
  RESET_START,
  RESET_SUCCESS,
  RESET_ERROR,
  RESET_DEFAULT_STATE,
} from './ForgotPasswordTypes';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  done: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_DEFAULT_STATE:
      return { ...INITIAL_STATE };
    case RESET_START:
      return { ...state, loading: true, error: null };

    case RESET_SUCCESS:
      return { ...state, loading: false, done: true };

    case RESET_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

import { format } from 'date-fns';
import {
  CLEAR_ERROR,
  GET_NOTIFICATIONS,
  NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATION,
} from './NotificationsTypes';

const INITIAL_STATE = {
  notifications: { content: [] },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      const activeContent =
        action.payload.page === 0
          ? action.payload.content
          : [...state.notifications.content, ...action.payload.content];

      return {
        ...state,
        loadingButton: false,
        notifications: {
          ...action.payload,
          unreadNotif: action.payload.unreadNotif,
          content: activeContent,
        },
      };

    case UPDATE_NOTIFICATION:
      return {
        ...state,
        loading: false,
      };

    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default: {
      return state;
    }
  }
};

import {
  PRODUCTS_START,
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR,
  SEND_PRODUCT_SUCCESS,
  PRODUCT_DELETE_SUCCESS,
  PRODUCTS_PAGINATION,
  CLEAR_ERRORS,
  ACTION_START,
  ACTION_SUCCESS,
  RESET_PRODUCTS
} from './ProductsTypes';

export const INITIAL_STATE = {
  error: null,
  loading: false,
  actionLoading: false,
  data: [],
  pagination: {
    current: 1,
  },
  notification: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PRODUCTS_START:
      return {
        ...state,
        loading: true,
      };

    case PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PRODUCTS_PAGINATION:
      return { ...state, pagination: action.payload };

    case SEND_PRODUCT_SUCCESS:
      return { ...state, loading: false };

    case PRODUCTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case RESET_PRODUCTS:
      return INITIAL_STATE;

    case ACTION_START:
      return {
        ...state,
        actionLoading: true,
      };

    case ACTION_SUCCESS:
      return {
        ...state,
        actionLoading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

import { combineReducers } from 'redux';
import LoginReducer from 'containers/public/Login/LoginReducer';
import ForgotPasswordReducer from 'containers/public/ForgotPassword/ForgotPasswordReducer';
import ResetPasswordReducer from 'containers/public/ResetPassword/ResetPasswordReducer';
import RegisterPasswordReducer from 'containers/public/Register/RegisterReducer';
import ProductFormReducer from 'containers/admin/ProductForm/ProductFormReducer';
import ProductsReducer from 'containers/admin/Products/ProductsReducer';
import SendProductsDetailsReducer from 'containers/admin/SendProductsDetails/SendProductsDetailsReducer';
import ProfileReducer from 'containers/admin/Profile/ProfileReducer';
import ProfileSettingsReducer from 'containers/admin/ProfileSettings/ProfileSettingsReducer';
import SuppliersReducer from 'containers/admin/Suppliers/SuppliersReducer';
import SupplierProductsReducer from 'containers/admin/SupplierProducts/SupplierProductsReducer';
import NotificationsReducer from 'components/Notifications/NotificationsReducer';
import AdminLayoutReducer from 'layouts/AdminLayout/AdminLayoutReducer';
import TermsAndConditionsReducer from 'components/TermsAndConditions/TermsAndConditionsReducer';
import PrivacyPolicyReducer from 'containers/admin/PrivacyPolicy/PrivacyPolicyReducer';
import TermsContainerReducer from 'containers/admin/TermsContainer/TermsContainerReducer';

export default combineReducers({
  login: LoginReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  register: RegisterPasswordReducer,
  productDetails: ProductFormReducer,
  profileSettings: ProfileSettingsReducer,
  products: ProductsReducer,
  sendProductsDetails: SendProductsDetailsReducer,
  profile: ProfileReducer,
  suppliers: SuppliersReducer,
  supplierProducts: SupplierProductsReducer,
  notifications: NotificationsReducer,
  admin: AdminLayoutReducer,
  termsAndConditions: TermsAndConditionsReducer,
  termsContainer: TermsContainerReducer,
  privacyPolicy: PrivacyPolicyReducer,
});

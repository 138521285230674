import React from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import * as RoleManager from 'services/role';
import { ReactComponent as ProductsSvg } from './products.svg';
import { useTranslation } from 'react-i18next';
import './Sidebar.scss';

type SidebarProps = {
  isOpen: boolean;
  closeSidebar: Function;
};

export const Sidebar: React.FC<SidebarProps> = ({ isOpen, closeSidebar }) => {
  const { t } = useTranslation('common');
  return (
    <Drawer
      className={`sidebar ${isOpen ? 'sidebar--open' : 'sidebar--closed'}`}
      anchor="left"
      open={true}
      variant="persistent"
    >
      <List
        className="sidebar-list"
        onClick={() => {
          closeSidebar();
        }}
      >
        <ListItemButton className="sidebar-item">
          <NavLink
            to="/dashboard/products"
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'item--selected' : ''
            }
            id="products"
          >
            <ListItemIcon>
              <Tooltip placement="right" title={t('products')}>
                <ProductsSvg className="sidebar-icon" />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={t('products')} />
          </NavLink>
        </ListItemButton>
        {RoleManager.isAbleTo('roles', 'suppliersOverview', 'view') && (
          <ListItemButton className="sidebar-item">
            <NavLink
              to="/dashboard/suppliers"
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'item--selected' : ''
              }
              id="suppliers"
            >
              <ListItemIcon>
                <Tooltip placement="right" title={t('suppliers')}>
                  <DashboardOutlinedIcon className="sidebar-icon" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('suppliers')} />
            </NavLink>
          </ListItemButton>
        )}
      </List>
      <ul
        className="bottom-links"
        onClick={() => {
          closeSidebar();
        }}
      >
        <li>
          <Link to="/dashboard/imprint">{t('imprint')}</Link>
        </li>
        <li>
          <Link to="/dashboard/privacy-policy">{t('privacyPolicy')}</Link>
        </li>
        <li>
          <Link to="/dashboard/terms-and-conditions">
            {t('termsAndConditions')}
          </Link>
        </li>
      </ul>
    </Drawer>
  );
};

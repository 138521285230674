import {
  GET_PRIVACY_START,
  GET_PRIVACY_SUCCESS,
  GET_PRIVACY_ERROR,
} from './PrivacyPolicyTypes';

const INITIAL_STATE = {
  data: {},
  error: null,
  loading: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PRIVACY_START:
      return {
        ...state,
        loading: true,
      };
    case GET_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_PRIVACY_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

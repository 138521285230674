import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputError, LanguageSwitcher } from 'components';
import { useForm } from 'react-hook-form';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  getCodeVerification,
  postCodeVerification,
} from 'containers/public/Login/LoginActions';
import { meraxisEmail } from 'services/config';
import { formatTime } from 'services/calculateDate';

import './TwoAuthenticationForm.scss';

type TwoAuthenticationFormProps = {
  login?: any;
};

export const TwoAuthenticationForm: React.FC<TwoAuthenticationFormProps> = ({
  login,
}) => {
  const { t } = useTranslation(['login', 'register']);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();
  const methods: any = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const logoImage = process.env.REACT_APP_API_URL_IMAGES + '/login-logo.svg';

  const submitForm = (data) => {
    dispatch(postCodeVerification(data, login));
  };

  const handleResendCode = () => {
    dispatch(
      getCodeVerification({ mobileNumber: login.data.user.mobileNumber })
    );
    setIsResendDisabled(true);
    setCountdown(120);
  };

  useEffect(() => {
    let timer;
    if (isResendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const phoneNumber = login?.data?.user?.mobileNumber;
  const parts = phoneNumber.split(' ');
  const prefix = parts.shift();
  const lastPart: any = parts?.pop();
  const obscuredPart = parts.map((part) => '*'.repeat(part.length)).join(' ');
  const lastThreeCharacters = lastPart?.slice(-3);

  return (
    <Box className="two-factor-page">
      <LanguageSwitcher />
      <div
        className="login-logo"
        style={{ backgroundImage: `url(${logoImage})` }}
      />
      <Typography variant="body1">{t('titleVerification')}</Typography>
      <Typography variant="subtitle1">
        {t('descriptionVerification')}{' '}
        {`${prefix} ${obscuredPart} ${lastThreeCharacters}`}
      </Typography>
      <form onSubmit={handleSubmit(submitForm)}>
        <TextField
          {...register('code', {
            required: true,
            validate: (value) => value.length === 6,
          })}
          margin="normal"
          variant="outlined"
          label={t('labelCode')}
          type="text"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 6,
            onKeyPress: (e: any) => {
              const isNumber = /\d/.test(e.key);
              const currentLength = e.target.value.length;
              if (!isNumber || currentLength >= 6) {
                e.preventDefault();
              }
            },
          }}
          helperText={
            errors.code && <InputError message={t('register:minLength')} />
          }
          onBlur={(e: any) => (e.target.value = e.target.value.trim())}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          data-qa="verify-button"
        >
          {t('verify')}
        </Button>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              className="resend-code"
              variant="subtitle1"
              color={!isResendDisabled ? 'primary' : ''}
              onClick={() => !isResendDisabled && handleResendCode()}
              style={{ cursor: isResendDisabled ? 'not-allowed' : 'pointer' }}
            >
              {t('resendCode')}
              {isResendDisabled && (
                <>
                  <AccessTimeIcon className="icon-access-time" />
                  {formatTime(countdown)}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Link className="problem-logging" href={`${meraxisEmail}`}>
              <Typography variant="subtitle1" align="right" data-qa="register">
                {t('problemLogging')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

import { createTheme, stepIconClasses } from '@mui/material';

import './fonts.scss';

const primaryColor = '#E4002B';
const primaryColorDark = '#C90327';
const secondaryColor = '#FFFFFF';
const warningColor = '#FAFAFA';
const infoColor = '#000000';
const secondaryButtonColor = '#0000000D';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      dark: primaryColorDark,
      contrastText: secondaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    warning: {
      main: warningColor,
    },
    info: {
      main: infoColor,
    },
    success: {
      main: secondaryButtonColor,
      contrastText: infoColor,
    },
  },
  typography: {
    fontFamily: '"Gotham", sans-serif',
    h1: {
      fontSize: '55px',
      fontFamily: 'Gotham-Bold',
      lineHeight: 1.1,
    },
    h2: {
      fontSize: '48px',
      fontFamily: 'Gotham-Bold',
    },
    h3: {
      fontSize: '36px',
      fontFamily: 'Gotham-Bold',
    },
    h4: {
      fontSize: '18px',
      fontFamily: 'Gotham-Bold',
    },
    h5: {
      fontSize: '12px',
      fontFamily: 'Gotham-Bold',
    },
    h6: {
      fontSize: '11px',
      fontFamily: 'Gotham-Bold',
    },
    subtitle1: {
      fontSize: '14px',
      fontFamily: 'Gotham',
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: '12px',
      fontFamily: 'Gotham',
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '14px',
      fontFamily: 'Gotham-Medium',
      lineHeight: 1.4,
    },
    body2: {
      fontSize: '12px',
      fontFamily: 'Gotham-Medium',
    },
    overline: {
      fontSize: '12px',
      fontFamily: 'Gotham-Light',
      textTransform: 'none',
    },
    button: {
      fontSize: '10px',
      fontFamily: 'Gotham-Medium',
      textTransform: 'none',
    },
    caption: {
      fontSize: '28px',
      fontFamily: 'Gotham-Bold',
      textTransform: 'none',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
          '&.Mui-error': {
            backgroundColor: '#ffebeb',
          },
          fontFamily: 'Gotham',
          fontSize: '14px',
          lineHeight: 1.4,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: primaryColor,
          fontSize: '16px',
        },
        root: {
          '&.MuiInputLabel-shrink': {
            color: 'black',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: primaryColor,
          backgroundImage: `url(${process.env.REACT_APP_API_URL_IMAGES}/background-30.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 17%',
          height: '80px',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          height: '80px',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
          borderBottom: '0',
        },
        rounded: {
          borderRadius: 5,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '8px',
          fontFamily: 'Gotham-Bold',
          borderRadius: '0',
          padding: '15px 20px',
          boxShadow: 'none',
          transition: '0.3s',
        },

        text: {
          fontFamily: 'Gotham',
          fontSize: '11px',
          color: '#000000',

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },

        contained: {
          textTransform: 'uppercase',
          letterSpacing: '3px',
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          color: primaryColor,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontFamily: 'Gotham-Medium',
          fontSize: '14px',
          lineHeight: 1.3,
          fill: '#0000004D',
        },
        root: {
          fill: secondaryButtonColor,

          [`&.${stepIconClasses.completed}`]: {
            fill: primaryColor,
          },
          [`&.${stepIconClasses.active}`]: {
            fill: primaryColor,
            '& text': {
              fill: secondaryColor,
            },
          },
        },
      },
    },

    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#434C501A',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: 'Gotham',
          margin: '5px 0 0 0',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'block',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 'none',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '20px 24px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        selected: {
          color: secondaryColor,
          backgroundColor: infoColor,

          '&:hover': {
            backgroundColor: infoColor,
          },
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '25px',
        },
      },
    },
  },
});

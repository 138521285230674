import { comapnyCertificatesValues } from 'services/config';
import {
  REGISTER_SUCCESS,
  REGISTER_START,
  REGISTER_ERROR,
  GET_COMPANY_INFORMATION_SUCCESS,
  SEND_ACTIVATION_CODE_SUCCESS,
  GET_ACTIVATION_CODE_SUCCESS,
  UPDATE_TIMER,
  ACTIVATION_CODE_ERROR,
} from './RegisterTypes';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  errorCode: false,
  codeValid: false,
  companyInformation: {},
  resendCodeTimer: 0,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REGISTER_START:
      return { ...state, loading: true, error: null, errorCode: false };

    case REGISTER_SUCCESS:
      return { ...state, loading: false };

    case GET_COMPANY_INFORMATION_SUCCESS:
      const certificationsArray = !!action.payload.certificates
        ? action.payload.certificates.split(',')
        : '';

      const certificatesResult: any = [];

      let certificateOther = '';

      if (certificationsArray) {
        certificationsArray.forEach((certification) => {
          const matchingCert = comapnyCertificatesValues.find(
            (cert) => cert.value === certification
          );

          if (matchingCert) {
            certificatesResult.push({
              value: matchingCert.value,
              label: matchingCert.label,
            });
          } else {
            certificatesResult.push({ value: 'OTHER', label: 'OTHER' });
            certificateOther = certification;
          }
        });
      }

      return {
        ...state,
        loading: false,
        companyInformation: {
          ...action.payload,
          certificates: certificatesResult,
          certificateOther: certificateOther,
        },
      };

    case REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload || true,
      };

    case ACTIVATION_CODE_ERROR:
      return {
        ...state,
        loading: false,
        codeValid: false,
        errorCode: action.payload || 'invalidInviteCode',
      };

    case GET_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SEND_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        codeValid: true,
        errorCode: false,
      };

    case UPDATE_TIMER:
      return {
        ...state,
        resendCodeTimer: action.payload,
      };

    default:
      return state;
  }
};

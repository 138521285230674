import * as AuthRepository from '../../../repository/auth';
import * as AuthService from './../../../services/auth';
import * as AccountService from 'services/account';
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CODE_SUCCESS,
} from './LoginTypes';
import { APP_REDIRECTS } from 'services/config';

export const loginUser = (data: any, callback) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });

    try {
      const response = await AuthRepository.loginUser(data);
      const redirectPath: any = window.localStorage.getItem('redirectPath');

      if (response.data && response.data.idToken) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });

        if (response.data.user.isMfaEnabled) {
          AuthService.saveUserToken(response.data.idToken);
          AuthService.saveUserRefreshToken(response.data.refreshToken);
          callback(response.data.user);
        } else {
          AuthService.saveUserToken(response.data.idToken);
          AuthService.saveUserRefreshToken(response.data.refreshToken);
          AccountService.saveAccount(response.data.user);
          if (redirectPath) {
            window.location.href = `${redirectPath}`;
            return window.localStorage.removeItem('redirectPath');
          }
          window.location.href = APP_REDIRECTS.PRODUCTS;
        }
      }
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: LOGIN_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const getCodeVerification = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });

    try {
      await AuthRepository.getCode(data);
      dispatch({
        type: CODE_SUCCESS,
      });
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: LOGIN_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const postCodeVerification = (data, login) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });

    try {
      await AuthRepository.postCode(data);
      const redirectPath: any = window.localStorage.getItem('redirectPath');
      if (login.data && login.data.idToken) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: login,
        });
        AuthService.saveUserToken(login.data.idToken);
        AuthService.saveUserRefreshToken(login.data.refreshToken);
        AccountService.saveAccount(login.data.user);
        if (redirectPath) {
          window.location.href = `${redirectPath}`;
          return window.localStorage.removeItem('redirectPath');
        }

        window.location.href = APP_REDIRECTS.PRODUCTS;
      }
    } catch (error: any) {
      const errorData = (error.response && error.response.data) || {};
      dispatch({
        type: LOGIN_ERROR,
        payload: errorData.title || errorData.detail,
      });
    }
  };
};

export const clearErrors = () => ({
  type: LOGIN_ERROR,
  payload: null,
});

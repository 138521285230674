import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { LinearProgress, Snackbar, Typography } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useTranslation } from 'react-i18next';
import { acceptFileUploaded } from 'services/config';

import './UploadBox.scss';

type UploadBoxProps = {
  onDrop?: Function;
  isDone?: boolean;
  acceptedFiles?: string;
  maxFilesize?: number;
  hideToolbar?: boolean;
  multipleUpload?: boolean;
  disabled?: boolean;
  acceptedFilesLabel?: string;
};

export const UploadBox: React.FC<UploadBoxProps> = ({
  onDrop = () => {},
  isDone,
  acceptedFiles = '',
  maxFilesize = 10242880, // 10MB
  hideToolbar = false,
  multipleUpload = false,
  disabled = false,
  acceptedFilesLabel,
}) => {
  const { t } = useTranslation('productForm');
  const [files, setFiles]: any = useState(null);
  const [filesRejected, setFilesRejected]: any = useState(null);

  useEffect(() => {
    if (isDone) {
      setFiles(null);
    }
  }, [isDone]);

  const styles = {
    disabled: {
      background: 'rgba(0, 0, 0, 0.12) !important',
      cursor: 'initial !important',
    },
  };

  return (
    <div className="upload-box">
      <Dropzone
        multiple={multipleUpload}
        maxSize={maxFilesize}
        accept={acceptedFiles ? acceptedFiles : acceptFileUploaded}
        onDropAccepted={(acceptedFiles) => {
          setFilesRejected(null);
          onDrop(acceptedFiles);
          setFiles(acceptedFiles);
        }}
        onDropRejected={(filesRejected) => {
          setFilesRejected(filesRejected);
        }}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="upload-dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography component="p" sx={disabled ? styles.disabled : {}}>
                <img src="/icons/upload-icon.svg" alt="Upload Icon" />
                {t('uploadBoxDragDocument')} <span>{t('uploadBoxBrowse')}</span>
              </Typography>
            </div>
          </section>
        )}
      </Dropzone>

      {filesRejected ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={!!filesRejected}
          autoHideDuration={8000}
          onClose={() => {
            setFilesRejected(null);
          }}
          message={
            <span id="message-id">
              {t('uploadBoxUnsupportedFile')}
              {acceptedFilesLabel}
              {t('file')}
            </span>
          }
        />
      ) : null}

      {!hideToolbar && (
        <div className="files">
          {(files || []).map((file) => (
            <div key={file.name} className="single-file">
              <InsertDriveFileOutlinedIcon color="primary" />
              <div className="file-content">
                <div className="file-name">{file.name}</div>
                <div className="file-progress">
                  <LinearProgress />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';

import './BoxWrap.scss';
import { Variant } from '@mui/material/styles/createTypography';

type BoxWrapProps = {
  className?: string;
  title?: React.ReactNode | string;
  variant?: Variant;
  color?: string;
  rightSide?: any;
  handleScroll?: (
    event: React.UIEvent<HTMLDivElement, UIEvent>
  ) => void | undefined;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void | undefined;
  description?: string;
  commentHeaderRight?: string;
  reasonHeaderRight?: string;
  children?: React.ReactNode;
};

export const BoxWrap: React.FC<BoxWrapProps> = ({
  className,
  title,
  variant,
  color,
  rightSide,
  children,
  handleScroll = () => {},
  onClick = () => {},
  description,
  commentHeaderRight,
  reasonHeaderRight,
}) => {
  return (
    <Box
      onScroll={handleScroll}
      className={`box-wrap ${className ? className : ''}`}
      onClick={onClick}
    >
      {(title || rightSide) && (
        <Grid container className="box-title">
          <Grid item xs={9}>
            <Typography
              variant={variant || 'body1'}
              component="h3"
              color={color || 'info'}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={3} className="box-right">
            <Typography component="h3">{rightSide}</Typography>
            <Typography component="p" className="comment-wrap">
              {commentHeaderRight && (
                <>
                  <Typography component="span" className="comment-status">
                    {reasonHeaderRight}
                  </Typography>
                  {commentHeaderRight}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className="box-wrap-divider" />
          </Grid>
        </Grid>
      )}

      <Box className="box-children">{children}</Box>

      {description && (
        <Typography component="span" className="description-box-table">
          {description}
        </Typography>
      )}
    </Box>
  );
};

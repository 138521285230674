export const ADMIN_PATH = '/dashboard';
export const PUBLIC_PATH = '';

export const ROUTES: Record<string, Record<string, string>> = {
  ADMIN: {
    DASHBOARD: 'dashboard',
    PRODUCTS: 'products',
    PRODUCT_CREATE_MATERIAL: 'product/create',
    PRODUCT_CREATE_BATCH: 'product/batch/create/:id',
    PRODUCT_VIEW_MATERIAL: 'product/view/material/:id',
    PRODUCT_VIEW_BATCH: 'product/view/batch/:id',
    PRODUCT_UPDATE_MATERIAL: 'product/material/:id',
    PRODUCT_UPDATE_BATCH: 'product/batch/:id',
    SEND_PRODUCT_DETAILS: 'product/:id/send',
    SUPPLIERS: 'suppliers',
    SUPPLIER_PRODUCTS: 'suppliers/:id',
    SUPPLIER_CONFIRMATION: 'suppliers/confirmation/:id',
    SUPPLIER_VIEW: 'suppliers/view/:id',
    PROFILE: 'profile',
    PROFILE_SETTINGS: 'settings',
    IMPRINT: 'imprint',
    TERMS: 'terms-and-conditions',
    PRIVACY_POLICY: 'privacy-policy',
  },
  PUBLIC: {
    LOGIN: 'login',
    REGISTER: 'register',
    RESET_PASSWORD: 'reset-password/:key',
    FORGOT_PASSWORD: 'forgot-password',
    TERMS: 'terms-and-conditions',
    NOT_FOUND: 'not-found',
  },
};

import {
  PRODUCTS_START,
  PRODUCTS_ERROR,
  PRODUCTS_SUCCESS,
  PRODUCT_DELETE_SUCCESS,
  PRODUCTS_PAGINATION,
  CLEAR_ERRORS,
  SUPPLIER_STATS,
  SUPPLIER_SUCCESS,
  RESET_SUPPLIER_PRODUCTS,
} from './SupplierProductsTypes';

export const INITIAL_STATE = {
  error: null,
  loading: false,
  data: [],
  pagination: {
    current: 1,
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PRODUCTS_START:
      return {
        ...state,
        loading: true,
      };

    case PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SUPPLIER_STATS:
      return {
        ...state,
        loading: false,
        supplierData: {
          ...state.supplierData,
          stats: action.payload,
        },
      };

    case SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        supplierData: {
          ...state.supplierData,
          ...action.payload,
        },
      };

    case PRODUCTS_PAGINATION:
      return { ...state, pagination: action.payload };

    case PRODUCTS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    case RESET_SUPPLIER_PRODUCTS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

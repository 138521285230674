import API from 'services/api';

export const loginUser = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  return await API.post('/auth/authenticate', { username, password });
};

export const getCode = async (data) => {
  return await API.post(`/auth/users/mfa`, data);
};

export const postCode = async (data) => {
  return await API.post(`/auth/users/mfa-verify`, data);
};

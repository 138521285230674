import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PercentPie = ({ data }) => {
  const { t } = useTranslation('suppliers');

  const approvedTextRef = useRef('');
  const rejectedTextRef = useRef('');

  useEffect(() => {
    approvedTextRef.current = `${t('approved')}: ${data.approved}`;
    rejectedTextRef.current = `${t('rejected')}: ${data.rejected}`;
  }, [data.approved, data.rejected, t]);

  const chartData = {
    labels: [`${t('approved')}`, `${t('rejected')}`],
    datasets: [
      {
        data:
          data.approved === 0 && data.rejected === 0
            ? [0, 0]
            : [data.approved, data.rejected],
        backgroundColor: ['#E20B32', '#0000000D'],
      },
    ],
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save();
      ctx.font = '12px Gotham-Medium';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillText(
        approvedTextRef.current,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
      ctx.textBaseline = 'top';
      ctx.fillText(
        rejectedTextRef.current,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + 5
      );
    },
  };

  return (
    <Box sx={{ minHeight: '250px' }}>
      <Doughnut
        data={chartData}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          aspectRatio: 2,
          cutout: '70%',
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                title: () => '',
                label: function (data) {
                  let label = ' ' + data.label + ': ' + data.parsed || '';
                  return label;
                },
              },
            },
          },
        }}
        plugins={[textCenter]}
      />
    </Box>
  );
};

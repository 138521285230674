import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms, updateTerms } from './TermsAndConditionsActions';

import './TermsAndConditions.scss';
import { getLocalLanguage } from 'services/account';

export const TermsAndConditions = () => {
  const defaultData = useSelector((state: any) => state.termsAndConditions);
  const termsData = defaultData.data;
  const localLang = getLocalLanguage();

  const dispatch = useDispatch();
  const { t } = useTranslation('privacyAndTerms');
  const [showModal, setShowModal] = useState(
    termsData?.shownTabs?.length ? true : false
  );
  const [activeTab, setActiveTab] = useState(0);

  const methods: any = useForm({
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    dispatch(getTerms(localLang));
    setShowModal(!!termsData?.shownTabs?.length);
  }, [dispatch, !!termsData?.shownTabs?.length, localLang]);

  const data = () => {
    dispatch(updateTerms());
    setShowModal(false);
  };

  return (
    <Dialog open={showModal} fullWidth className="terms">
      <DialogTitle>
        <Typography variant="h4">{t('titleTerms')}</Typography>
        <Tabs
          value={activeTab}
          onChange={(e, index) => {
            setActiveTab(index);
          }}
          style={{ marginTop: '15px' }}
        >
          {(termsData?.shownTabs || []).map((item, index) => (
            <Tab
              key={index}
              label={
                <Typography variant="body2" textTransform="uppercase">
                  {t(item)}
                </Typography>
              }
            />
          ))}
        </Tabs>
      </DialogTitle>

      {termsData?.shownTabs?.length && Object.keys(termsData).length !== 0 && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(data)}>
            <DialogContent>
              {termsData[termsData?.shownTabs[activeTab]] && (
                <Typography
                  style={{
                    maxHeight: '350px',
                    maxWidth: '550px',
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: termsData[termsData?.shownTabs[activeTab]],
                  }}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                {t('accept')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      )}
    </Dialog>
  );
};

import React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { InputError } from '../InputError/InputErrors';
import { useTranslation } from 'react-i18next';

type MultiDropdownButtonProps = {
  label?: string;
  options?: any;
  errorKey?: string;
  name?: any;
  defaultValue?: any;
  limitTags?: any;
  onChange?: any;
  value?: any;
  required?: boolean;
  placeholder?: any;
  multiple?: boolean;
  dataQA?: string;
  disabled?: boolean;
  translationNamespaces?: string;
};

export const MultiDropdownButton: React.FC<MultiDropdownButtonProps> = ({
  label,
  options,
  errorKey,
  name,
  limitTags = undefined,
  onChange,
  value,
  required,
  placeholder,
  multiple = true,
  dataQA = name,
  disabled,
  defaultValue,
  translationNamespaces,
}) => {
  const { t } = useTranslation(translationNamespaces);

  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      options={options}
      getOptionLabel={(option: any) => t(option.label)}
      filterSelectedOptions={true}
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {t(option.label)}
        </li>
      )}
      disableClearable={true}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          {...name}
          disabled={disabled}
          placeholder={!defaultValue && placeholder}
          variant="outlined"
          label={label}
          error={!!errorKey}
          fullWidth
          required={required}
          data-qa={dataQA}
          helperText={!!errorKey && <InputError message={errorKey} />}
        />
      )}
    />
  );
};

import React, { useState } from 'react';
import { Arrow } from '../Arrow/Arrow';

import './Sorter.scss';

type SorterProps = {
  label?: string;
  handleSort: Function;
  initialSort: string;
  activeFieldSort?: any;
};

export const Sorter: React.FC<SorterProps> = ({
  label,
  handleSort,
  initialSort,
  activeFieldSort,
}) => {
  const activeField = activeFieldSort;

  const handleSortClick = async (direction: string) => {
    await handleSort(direction);
  };

  return (
    <>
      {label}
      <span className="cell-sorter">
        <span
          onClick={() => handleSortClick('asc')}
          className={activeField?.sort === `${initialSort},asc` ? 'active' : ''}
        >
          <Arrow color="#000" direction="up" />
        </span>
        <span
          onClick={() => {
            handleSortClick('desc');
          }}
          className={
            activeField?.sort === `${initialSort},desc` ? 'active' : ''
          }
        >
          <Arrow color="#000" direction="down" />
        </span>
      </span>
    </>
  );
};

import API from 'services/api';

export const getPrivacyPolicy = async (lang) => {
  return await API.get(`/auth/legal/privacy-policy?lang=${lang}`);
};

export const getTerms = async (lang) => {
  return await API.get(`/auth/legal/terms-conditions?lang=${lang}`);
};

export const getPrivacyPolicyTermsAndConditions = async (lang) => {
  return await API.get(`/auth/legal?lang=${lang}`);
};

export const updateTermsAndConditions = async () => {
  return await API.patch(`/auth/legal/accept-terms-privacy`);
};

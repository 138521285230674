import React from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type PopupProps = {
  title: string;
  modalOpen: boolean;
  handleModalClose?: () => void;
  handleModalSubmit?: () => void;

  modalContent?: React.ReactNode;
  modalActions?: React.ReactNode;
};

export const CustomModal = (props: PopupProps) => {
  const {
    title,
    modalOpen,
    handleModalSubmit,
    handleModalClose,
    modalContent,
    modalActions,
  } = props;

  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogTitle>
        <Grid container item xs={12} justifyContent={'space-between'}>
          <Typography variant="h4">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ padding: '20px', textAlign: 'center' }}>
        {modalContent}
      </DialogContent>

      {!!handleModalSubmit && (
        <DialogActions style={{ padding: '24px' }}>
          {modalActions}
        </DialogActions>
      )}
    </Dialog>
  );
};

import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';

import './InputError.scss';

type InputErrorProps = {
  message?: string;
};

export const InputError: React.FC<InputErrorProps> = ({ message }) => (
  <Typography className="input-error">
    <ErrorOutlineOutlinedIcon color="primary" fontSize="small" />
    <Typography component="span">{message}</Typography>
  </Typography>
);

import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from './AdminLayoutTypes';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  notification: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };

    default:
      return state;
  }
};

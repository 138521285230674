import React, { FC, Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box, Snackbar } from '@mui/material';
import { isAuth, logoutUser } from 'services/auth';
import { Header, Loading, Sidebar } from 'components';
import { LOCAL_STORAGE_ITEMS } from 'services/config';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGlobalNotification } from './AdminLayoutActions';
import { useTranslation } from 'react-i18next';
import './AdminLayout.scss';
import { TermsAndConditions } from 'components/TermsAndConditions/TermsAndConditions';

export const AdminLayout: FC = () => {
  const { t } = useTranslation(['products', 'suppliers', 'productForm']);
  const dispatch = useDispatch();
  const notification = useSelector((state: any) => state.admin.notification);

  const [isOpenSidebar, setIsOpenSidebar] = useState(
    !(window.outerWidth < 1000)
  );

  const closeSidebar = () => {
    let savedSidebar: any = localStorage.getItem(LOCAL_STORAGE_ITEMS.SIDEBAR);

    if (
      window.outerWidth < 1000 ||
      (savedSidebar && !parseInt(savedSidebar, 10))
    ) {
      setIsOpenSidebar(false);
    } else {
      setIsOpenSidebar(true);
    }
  };

  const deleteNotification = () => {
    dispatch(deleteGlobalNotification());
  };

  const toggleSidebar = () => {
    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.SIDEBAR,
      isOpenSidebar ? '0' : '1'
    );
    setIsOpenSidebar(!isOpenSidebar);
  };

  useEffect(() => {
    closeSidebar();
    window.addEventListener('resize', closeSidebar);

    return () => {
      window.removeEventListener('resize', closeSidebar);
    };
  }, []);

  if (!isAuth()) {
    const redirectPath = window.location.pathname;

    if (redirectPath) {
      window.localStorage.setItem(
        'redirectPath',
        decodeURIComponent(window.location.href)
      );
    }
    logoutUser();
    return <Navigate to="/login" replace />;
  }

  return (
    <Box className="admin">
      <Header isOpen={isOpenSidebar} toggleSidebar={toggleSidebar} />
      <Suspense fallback={<Loading />}>
        <Sidebar isOpen={isOpenSidebar} closeSidebar={closeSidebar} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <Box className={`content ${isOpenSidebar ? 'content--open' : ''}`}>
          <Outlet />
          <TermsAndConditions />
        </Box>
      </Suspense>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!notification}
        onClose={deleteNotification}
        autoHideDuration={3000}
        message={<span id="message-id">{t(notification)}</span>}
      />
    </Box>
  );
};

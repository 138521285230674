import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from './AdminLayoutTypes';

export const setGlobalNotification = (notification: string) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION,
      payload: notification,
    });
  };
};

export const deleteGlobalNotification = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_NOTIFICATION,
    });
  };
};
